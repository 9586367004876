import Svg, {
    Circle,
    Ellipse,
    G,
    Text,
    TSpan,
    TextPath,
    Path,
    Polygon,
    Polyline,
    Line,
    Rect,
    Use,
    Image,
    Symbol,
    Defs,
    LinearGradient,
    RadialGradient,
    Stop,
    ClipPath,
    Pattern,
    Mask,
} from 'react-native-svg';
import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";

export function LineChartWidget({ labels, datasets }) {
    return <LineChart
        data={{
            labels,
            datasets: datasets.map(({ data, color }) => ({
                data,
                color: (opacity = 1) => `rgba(67, 150, 183, ${opacity})`
            }))
            // legend: ["Rainy Days"]
        }}
        width={Dimensions.get("window").width - 30} // from react-native
        height={180}
        yAxisLabel=""
        yAxisSuffix=""
        yAxisInterval={1}
        chartConfig={{
            backgroundColor: "#e26a00",
            backgroundGradientFrom: "#fb8c00",
            backgroundGradientTo: "#ffa726",
            decimalPlaces: 0,
            color: (opacity = 1) => `rgba(67, 150, 183, ${opacity})`,
            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            style: {
                borderRadius: 12
            },
            propsForDots: {
                r: "6",
                strokeWidth: "2",
                stroke: "#ffa726"
            }
        }}
        bezier
        style={{
            borderRadius: 16
        }}
    />
}
export default function SvgExample() {
    return (
        <View
            style={[
                { height: 200, alignItems: 'center', justifyContent: 'center' },
            ]}
        >
            <Svg height="50%" width="50%" viewBox="0 0 100 100">
                <Circle
                    cx="50"
                    cy="50"
                    r="45"
                    stroke="blue"
                    strokeWidth="2.5"
                    fill="green"
                />
                <Rect
                    x="15"
                    y="15"
                    width="70"
                    height="70"
                    stroke="red"
                    strokeWidth="2"
                    fill="yellow"
                />
            </Svg>
        </View>
    );
}

export function SvgExample2() {
    return <Svg height="160" width="200">
        <Text y="20" dx="5 5" fill="black">
            <TSpan x="10">tspan line 1</TSpan>
            <TSpan x="10" dy="15">
                tspan line 2
            </TSpan>
            <TSpan x="10" dx="10" dy="15">
                tspan line 3
            </TSpan>
        </Text>
        <Text x="10" y="60" fill="green" fontSize="14">
            <TSpan dy="5 10 20">12345</TSpan>
            <TSpan fill="blue" dy="15" dx="0 5 5">
                <TSpan>6</TSpan>
                <TSpan>7</TSpan>
            </TSpan>
            <TSpan dx="0 10 20" dy="0 20" fontWeight="bold" fontSize="12">
                89a
            </TSpan>
        </Text>
        <Text y="140" dx="0 5 5" dy="0 -5 -5" fill="yellow">
            delta on text
        </Text>
    </Svg>

}

export function SvgExample3({ height = 200, width = 200 }) {
    function x(x) {
        return x;
    }

    function y(y) {
        return height - y;
    }

    function XAxis() {
        return <G id="xGrid">
            <Line x1={x(padding)} x2={x(width - padding)} y1={y(padding)} y2={y(padding)} stroke="black"></Line>
        </G>
    }

    function Grid() {
        <G id="Grid">
            <Line x1={x(padding)} x2={x(width - padding)} y1={y(padding)} y2={y(padding)} stroke="black"></Line>
        </G>
    }

    const padding = 10;

    return <Svg width={width} height={height}>
        <XAxis />
        {/* <g class="grid y-grid" id="yGrid">
            <line x1="90" x2="705" y1="370" y2="370"></line>
        </g>
        <g class="labels x-labels">
            <text x="10" y="150">2008</text>
            <text x="40" y="400">2009</text>
            <text x="80" y="400">2010</text>
            <text x="538" y="400">2011</text>
            <text x="684" y="400">2012</text>
            <text x="400" y="440" class="label-title">Year</text>
        </g>
        <g class="labels y-labels">
            <text x="80" y="15">15</text>
            <text x="80" y="131">10</text>
            <text x="80" y="248">5</text>
            <text x="80" y="373">0</text>
            <text x="50" y="200" class="label-title">Price</text>
        </g>
        <g class="data" data-setname="Our first data set">
            <circle cx="90" cy="192" data-value="7.2" r="4"></circle>
            <circle cx="240" cy="141" data-value="8.1" r="4"></circle>
            <circle cx="388" cy="179" data-value="7.7" r="4"></circle>
            <circle cx="531" cy="200" data-value="6.8" r="4"></circle>
            <circle cx="677" cy="104" data-value="6.7" r="4"></circle>
        </g> */}
    </Svg>
}