import React from 'react';
import { Card, Paragraph } from 'react-native-paper';
import { useNavigation } from "@react-navigation/native";
import * as FileSystem from 'expo-file-system';

export default function PinListItem({ boardId, item }) {
    const itemId = item.id;
    const navigation = useNavigation();
    const hasTitle = item.title.length > 0;
    
    let url = item.thumbnailURL;

    return <Card onPress={() => navigation.navigate('PinDetail', { boardId, itemId })}>
        <Card.Cover style={{ height: hasTitle ? 160 : 180 }} source={{ uri: url }} />
        {hasTitle && <Card.Content style={{ paddingBottom: 0 }}>
            <Paragraph numberOfLines={3}>{item.title}</Paragraph>
        </Card.Content>}
    </Card>
}