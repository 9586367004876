import React, { useContext, useRef, useState } from 'react';
import { StyleSheet, View, Text, ImageBackground } from 'react-native';
import ViewPager from '@react-native-community/viewpager';
import { AuthContext } from '../../state/AuthContext';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { useNavigation } from '@react-navigation/core';

const OnboardingScreen = () => {
    const navigation = useNavigation();
    const { state: { themeState } } = useContext(AuthContext);

    const { continue_label, skip_label, start_label, items } = themeState.onboarding;

    const totalPages = items.length;
    const [currentPage, setCurrentPage] = useState(0);
    const viewPager = useRef(null);

    const onNextTap = () => {
        const pager: ViewPager = viewPager.current;
        if (currentPage + 1 === totalPages) {
            onSkipTap();
            return;
        }

        pager.setPage(currentPage + 1);
    };

    const onSkipTap = () => {
        navigation.navigate('Welcome')
    }

    function isLastPage() {
        return currentPage + 1 === totalPages;
    }

    return (
        <View style={{ flex: 1 }}>
            <ViewPager
                style={styles.viewPager} initialPage={0} showPageIndicator={true} ref={viewPager}
                onPageSelected={(e) => { setCurrentPage(e.nativeEvent.position) }}
            >
                {items.map((page, idx) => <View key={idx}><PageView idx={idx} page={page} /></View>)}
            </ViewPager>
            <Footer onContinue={onNextTap} onSkip={onSkipTap} isLastPage={isLastPage()} continueTitle={continue_label} skipTitle={skip_label} startTitle={start_label} />
        </View>
    );
};

function PageView({ idx, page: { title, subtitle, background_image } }) {
    return <View style={styles.page}>
        <ImageBackground source={{ uri: background_image }} style={styles.image}>
            <View style={styles.titleContainer}>
                <Text style={{ color: 'white', fontSize: 24, paddingHorizontal: 12, paddingTop: 12 }}>{title}</Text>
                <Text style={{ color: 'white', fontSize: 18, paddingHorizontal: 12, paddingBottom: 12 }}>{subtitle}</Text>
            </View>
        </ImageBackground>
    </View>
}

function Footer({ continueTitle = 'Continue', skipTitle = 'Skip', startTitle = 'Get Started', isLastPage, onContinue, onSkip }) {
    return <View style={styles.footer}>
        <View style={{ marginBottom: 6 }}>
            <PrimaryButton title={isLastPage ? startTitle : continueTitle} onPress={onContinue} />
        </View>
        {
            !isLastPage &&
            <SecondaryButton title={skipTitle} onPress={onSkip} />
        }
    </View>
}

const styles = StyleSheet.create({
    viewPager: {
        flex: 1,
    },
    page: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1
    },
    titleContainer: {
        backgroundColor: 'rgba(52, 52, 52, 0.8)',
        color: 'white',
        bottom: 150
    },
    footer: {
        flex: 1,
        position: 'absolute',
        paddingLeft: 15,
        paddingRight: 15,
        bottom: 50,
        height: 80,
        right: 0,
        left: 0,
        zIndex: 1000
    },
    imgContainer: {
        flexDirection: 'row',
        flex: 1
    },
    image: {
        resizeMode: 'cover',
        flex: 1,
        width: undefined,
        height: undefined,
        aspectRatio: 1,
        justifyContent: "flex-end",
        maxWidth: '100%'
    }
});

export default OnboardingScreen;