import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { FlatList, Image, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Text, View } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';
import * as WebBrowser from 'expo-web-browser';

export function DocumentItem({ document }) {
    const navigation = useNavigation();

    function onPress() {
        if (Platform.OS == 'android') {
            WebBrowser.openBrowserAsync(document.fileviewer_url ?? document.url);
            return;
        }

        navigation.navigate('DocumentDetail', { docId: document.id, url: document.url, name: document.name })
    }

    return <TouchableOpacity onPress={onPress}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 6 }}>
            <Image style={{ height: 60, width: 40, resizeMode: 'contain' }} source={{ uri: document.thumbnail_url }} />
            <View style={{ flex: 1, marginLeft: 12 }}>
                <Text style={{ paddingBottom: 4, ...Fonts.subtitle }}>{document.name}</Text>
                <Text style={{ ...Fonts.badge }}>{document.date}</Text>
            </View>
        </View>
    </TouchableOpacity>
}

export default function FolderDetail({ route, navigation }) {
    const { actions: { getFolder } } = React.useContext(AuthContext);
    const folder = getFolder(route.params.folderId);

    React.useEffect(() => {
        navigation.setOptions({
            headerTitle: folder.folder_name
        });
    });

    return <FlatList
        data={folder.documents}
        renderItem={({ item }) => <DocumentItem document={item} />}
    />
}
