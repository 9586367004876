import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';

import useColorScheme from '../hooks/useColorScheme';
import { AuthContext } from '../state/AuthContext';
import { Icon } from '../components/Themed';
import { getNavigator } from '../screens';

const BottomTab = createBottomTabNavigator();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const { state, actions: { signOut, getTabColor, getScreenUserData } } = React.useContext(AuthContext);
  const { themeState: { tabs }} = state;

  const initialRoute = tabs[0].url.slice(1);
  const validTabs = tabs.filter(excludeWithNoUserData);

  function excludeWithNoUserData(tab) {
    const name = tab.url.slice(1);
    const userData = getScreenUserData(name);
    return userData !== null;
  }

  return (
    <BottomTab.Navigator
      initialRouteName={initialRoute}
      tabBarOptions={{ activeTintColor: getTabColor() }}>
      {validTabs.map((each) => {
        const TabNavigator = getNavigator(each.widget);
        const title = each.title;
        const name = each.url.slice(1);

        return <BottomTab.Screen
          key={name}
          name={name}
          component={TabNavigator}
          options={{
            title: title,
            tabBarIcon: ({ color }) => <TabBarIcon name={each.icon} color={color} />
          }}
        />
      })}
    </BottomTab.Navigator>
  );
}

function TabBarIcon(props: { name: string; color: string }) {
    return <Icon size={30} style={{ marginBottom: -3 }} {...props} />;
}
