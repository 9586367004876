export default {
  confirm: 'Bestätigen',
  cancel: 'Abbrechen',
  pinboard: {
    camera_permission_denied: 'Wir benötigen Zugriff auf das Foto-Album für dieses Feature.',
    camera_roll_permission_denied: 'Wir benötigen Zugriff auf die Kamera für dieses Feature.',
    take_photo: 'Foto aufnehmen',
    choose_photo: 'Bestehendes Foto hinzufügen',
    create_space: 'Bereich anlegen',
    add_link: 'Link hinzufügen',
    change_title: 'Titel bearbeiten',
    confirm_delete_pin_message: 'Diese Pinnwand wird von der Cloud und all Ihren Geräten gelöscht.',
    delete_pin: 'Pin löschen',
    delete_board: 'Board löschen'
  }
};
