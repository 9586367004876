import React, { useReducer, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ky from 'ky';

const API_PREFIX = 'https://api.bitmio.com/v1';
// const API_PREFIX = 'http://localhost:5000/v1';

const initialState = {
    appId: null,
    themeState: null,
    userState: null,
    demoState: null,
    isLoading: true,
    isLoadingState: false,
    isSignout: false,
    userToken: null,
    pushToken: null,
    projectId: null
};

export const AUTH_STATE_CHANGED = "AUTH_STATE_CHANGED";

const reducer = (prevState, action) => {
    console.info('ACTION', action.type, Object.keys(action));

    switch (action.type) {
        case 'RESTORE_THEME':
            return {
                ...prevState,
                appId: action.appId,
                themeState: action.themeState
            }
        case 'UPDATE_THEME':
            return {
                ...prevState,
                appId: action.appId,
                themeState: action.themeState
            }
        case 'RESTORE_STATE':
            return {
                ...prevState,
                userState: action.userState,
                demoState: action.demoState,
                userToken: action.userToken,
                pushToken: action.pushToken,
                projectId: action.projectId,
                isLoading: false
            }
        case 'LOAD_USER_STATE':
            return {
                ...prevState,
                isLoadingState: true
            }
        case 'UPDATE_USER_STATE':
            if (prevState.userState?.revision === action.userState.revision) {
                return {
                    ...prevState,
                    isLoadingState: false
                }
            }

            return {
                ...prevState,
                isLoadingState: false,
                userState: action.userState
            }
        case 'SIGN_IN':
            return {
                ...prevState,
                isSignout: false,
                userToken: action.userToken,
                userState: action.userState
            };
        case 'SIGN_OUT':
            return {
                ...prevState,
                isSignout: true,
                userToken: null,
                userState: {}
            };
        case 'UPDATE_PUSH_TOKEN':
            return {
                ...prevState,
                pushToken: action.pushToken
            };
        case 'UPDATE_PROJECT':
            return {
                ...prevState,
                projectId: action.projectId
            };
    }
    return prevState;
};

const AuthContext = React.createContext();

async function fetchUserState(appId: String, userToken: String, pushToken: String | null, projectId: String | null) {
    console.info('fetchUserState', appId, userToken);
    console.info(projectId)

    const url = `${API_PREFIX}/${appId}/bits/state?expo_push_token=${pushToken || ''}&project=${projectId || ''}`;

    console.info('Fetching', url);

    try {
        const res = await ky.get(url, {
            headers: { 'Authorization': `Bearer ${userToken}` }
        });

        const state = await res.json();

        console.info('fetchUserState success');

        return state;
    } catch (err) {
        if (err.response) {
            const body = await err.response.json();
            console.error('fetchUserState', body);
            return;
        }

        console.error('fetchUserState', err, appId, userToken);
    }
}

async function fetchDemoState(appId: String, pushToken: String | null) {
    const url = `${API_PREFIX}/${appId}/bits/demo_state?expo_push_token=${pushToken || ''}`;

    try {
        const result = await ky.get(url).json();
        return result;
    } catch (err) {
        console.error(err);
    }
}

async function fetchTheme(appId: String) {
    const url = `${API_PREFIX}/${appId}/config`;
    console.info('fetchTheme', appId);

    try {
        const result = await ky.get(url).json();
        console.info('Loaded theme', result.id);
        return result;
    } catch (err) {
        console.error('fetchTheme', err);
    }
}

async function setKV(appId, userToken, key, value) {
    console.info('setKV', key);

    const url = `${API_PREFIX}/${appId}/kv?key=${key}`;

    try {
        await ky.post(url, {
            json: value,
            headers: { 'Authorization': `Bearer ${userToken}` }
        }).json();
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
}

async function getKV(appId, userToken, key) {
    console.info('getKV', key);

    const url = `${API_PREFIX}/${appId}/kv?key=${key}`;

    try {
        const result = await ky.get(url, {
            headers: { 'Authorization': `Bearer ${userToken}` }
        }).json();
        return result;
    } catch (err) {
        console.error(err);
    }
}

async function readCachedUserState() {
    return await AsyncStorage.getItem('userState');
}

async function writeCachedUserState(state) {
    return await AsyncStorage.setItem('userState', state);
}

async function readUserToken() {
    return await AsyncStorage.getItem('refreshToken');
}

async function saveUserToken(userToken, refreshToken) {
    await AsyncStorage.setItem('userToken', userToken);
    await AsyncStorage.setItem('refreshToken', refreshToken);
}

async function readPushToken() {
    return await AsyncStorage.getItem('pushToken');
}

async function savePushToken(pushToken) {
    await AsyncStorage.setItem('pushToken', pushToken);
}

async function readProjectId() {
    return await AsyncStorage.getItem('projectId');
}

async function saveProjectId(projectId) {
    await AsyncStorage.setItem('projectId', projectId);
}

let userPropsCache;

async function warmUserPropsCache() {
    const data = await AsyncStorage.getItem('userPropertiesV1');

    let obj;

    if (!data) {
        obj = {};
    } else {
        obj = JSON.parse(data);
    }

    userPropsCache = obj;
}

async function writeUserPropertiesAsync(properties) {
    userPropsCache = properties;

    const data = JSON.stringify(properties);
    await AsyncStorage.setItem('userPropertiesV1', data);
}

function getUserProperties() {
    return userPropsCache;
}

function getUserProperty(key) {
    const props = getUserProperties();

    return props[key];
}

async function setUserProperty(key, value) {
    const props = getUserProperties();

    props[key] = value;

    await writeUserPropertiesAsync(props);
}

function AuthProvider({ locale, appId, hasAppSwitcher, ...props }) {
    const [activeApp, setActiveApp] = useState(appId);
    const [kollabToken, setKollabToken] = useState(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [userData, setUserData] = useState({});

    function auth(appId, token) {
        console.info('🧐auth', appId, token);

        if (appId === 'kollab') {
            setKollabToken(token);
        }
    }

    const actions = {
        dispatch,
        init: async (appId = activeApp) => {
            console.info('🧐init');

            const themeState = await fetchTheme(appId);

            await warmUserPropsCache();

            if (!themeState) {
                console.info('init - no theme');
                return;
            }

            dispatch({ type: 'RESTORE_THEME', themeState, appId });

            const userToken = await readUserToken();
            const pushToken = await readPushToken();
            const projectId = await readProjectId();

            auth(appId, userToken);

            if (!userToken) {
                dispatch({ type: 'RESTORE_STATE' });
                return;
            }

            const userState = await fetchUserState(appId, userToken, pushToken, projectId);

            if (!userState) {
                dispatch({ type: 'RESTORE_STATE' });
                return;
            }

            dispatch({ type: 'RESTORE_STATE', userToken, pushToken, userState, projectId });
        },
        signIn: async data => {
            const url = `${API_PREFIX}/${state.appId}/login`;

            try {
                const result = await ky.post(url, { json: data }).json();
                const {
                    access_token: userToken,
                    refresh_token: refreshToken
                } = result;

                await saveUserToken(userToken, refreshToken);
                const userState = await fetchUserState(state.appId, userToken, null, null);

                dispatch({ type: 'SIGN_IN', userToken: refreshToken, userState });
                auth(activeApp, userToken);

                return true;
            } catch (err) {
                console.error(err);
                return false;
            }
        },
        signOut: async () => {
            await AsyncStorage.removeItem('userToken');
            await AsyncStorage.removeItem('refreshToken');

            dispatch({ type: 'SIGN_OUT' })
        },
        signUp: async data => {
            const url = `${API_PREFIX}/${state.appId}/signup`;

            try {
                const result = await ky.post(url, { json: data }).json();
                const {
                    access_token: userToken,
                    refresh_token: refreshToken
                } = result;

                await saveUserToken(userToken, refreshToken);
                const userState = await fetchUserState(activeApp, userToken, state.pushToken, null);

                dispatch({ type: 'SIGN_IN', userToken: refreshToken, userState });
                auth(activeApp, refreshToken);

                return true;
            } catch (err) {
                console.error(err);
                return false;
            }
        },
        demo: async data => {
            const userState = await fetchDemoState(state.appId, state.pushToken);
            const userToken = 'demo';
            dispatch({ type: 'SIGN_IN', userToken, userState });
        },
        fetchState: async ({ projectId } = {}) => {
            console.info('fetchState', state.projectId);

            if (state.isLoadingState) {
                console.info('Cancel state fetching');
                return;
            }

            dispatch({ type: 'LOAD_USER_STATE' });

            if (state.userToken === 'demo') {
                const userState = await fetchDemoState(state.appId, state.pushToken);

                if (!userState) {
                    return;
                }

                dispatch({ type: 'UPDATE_USER_STATE', userState });
            } else {
                const userState = await fetchUserState(activeApp, state.userToken, state.pushToken, projectId || state.projectId);

                if (!userState) {
                    return;
                }

                dispatch({ type: 'UPDATE_USER_STATE', userState });
            }
        },
        async fetchApps() {
            const { userToken } = state;

            const url = `${API_PREFIX}/${appId}/apps`;

            const res = await ky.get(url, {
                headers: { 'Authorization': `Bearer ${userToken}` }
            });

            const body = await res.json();

            return body.items;
        },
        async setApp(appId) {
            console.info('🧐setApp', appId);

            setActiveApp(appId);

            await actions.init(appId);
        },
        getAssetCreds: () => {
            console.info('asset creds', state.userState.creds);
            return state.userState.creds.assets;
        },
        async getKV(key) {
            return await getKV(state.appId, state.userToken, key);
        },
        async setKV(key, value) {
            return await setKV(state.appId, state.userToken, key, value);
        },
        setProject: async (projectId: String) => {
            console.info('setProject', projectId);

            await saveProjectId(projectId);

            dispatch({ type: 'UPDATE_PROJECT', projectId });

            actions.fetchState({ projectId });
        },
        setPushToken: async (pushToken: String) => {
            if (!pushToken) {
                return;
            }

            await savePushToken(pushToken);

            dispatch({ type: 'UPDATE_PUSH_TOKEN', pushToken })
        },
        getScreenThemeData: (route: String) => {
            console.info('getScreenThemeData()', route);

            return state.themeState?.tabs?.find(each => each.url.slice(1) === route);
        },
        getScreenUserData: (route: String) => {
            console.info('getScreenUserData', route);
            const themeData = actions.getScreenThemeData(route);
            const dataKey = themeData.data;

            console.info('getScreenUserData()', route, dataKey);

            return state.userState[dataKey];
        },
        writeUserData: (key: String, value) => {
            let newUserData = {
                ...userData
            };

            newUserData[key] = value;

            setUserData(newUserData);
        },
        readUserData: (key: String) => {
            return userData[key];
        },
        getCardv1: (cardId) => {
            const allCards = state.userState.overview.map(each => each.cards).flat();
            return allCards.find((each) =>
                each.id === cardId
            );
        },
        getCard: (cardId) => {
            const allCards = state.userState.activities_v2.map(each => each.cards).flat();
            const card = allCards.find((each) =>
                each.id === cardId
            );

            if (!card) {
                return actions.getCardv1(cardId);
            }

            return card;
        },
        getFolders: () => state.userState.documents,
        getFolder: (folderId) => {
            return actions.getFolders().find(each => each.id === folderId);
        },
        getDocument: (docId) => {
            const allDocs = actions.getFolders().map(each => each.documents).flat();
            return allDocs.find(each => each.id === docId);
        },
        getContacts: () => state.userState.contacts,
        getContact: (contactId) => {
            return actions.getContacts().find(each => each.id === contactId);
        },
        getTodoCompleted: (cardId, itemId) => {
            const key = `todo_status_${itemId}`;

            const isCompleted = getUserProperty(key);

            return isCompleted === true;
        },
        setTodoCompleted: async (cardId, itemId, isCompleted) => {
            const key = `todo_status_${itemId}`;

            await actions.setKV(key, isCompleted);
            await setUserProperty(key, isCompleted);
        },
        mapCardBadges: (card) => {
            const badge = card.badges.find(each => each.id === 'has_todos');

            if (badge) {
                const totalTodos = badge.value.totalTodos;
                const completedTodos = card.checklists.reduce(
                    (prev, eachList) => prev + eachList.items.reduce(
                        (prevListCount, eachItem) => prevListCount + (actions.getTodoCompleted(null, eachItem.id) ? 1 : 0), 0),
                    0);

                const label = `${completedTodos}/${totalTodos}`;

                badge.value.completedTodos = completedTodos;
                badge.label = label;
            }

            return card;
        },
        updateCardBadges: (card) => {

        },
        getPinboards: () => state.userState.pinboards,
        getPrimaryColor: () => `#${state.themeState.primary_color}`,
        getTabColor: () => `#${state.themeState.tab_icon_color}`
    };

    return (
        <AuthContext.Provider
            value={{
                API_PREFIX,
                activeApp,
                kollabToken,
                hasAppSwitcher,
                state,
                actions,
            }
            }
        >
            {props.children}
        </AuthContext.Provider >
    );
}

export { AuthProvider, AuthContext };
