import React, { useState } from 'react';
import { ActivityIndicator } from 'react-native';

import { Text, useThemeColor, View } from '../components/Themed';

export default function SplashScreen() {
    const color = useThemeColor({}, 'tabIconSelected');

    return (
        <View style={{ flex: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size="large" color={color} />
        </View>
    );
}