import { useNavigation } from '@react-navigation/native';
import React, { useState, useContext, useEffect } from 'react';
import { FlatList, Image, RefreshControl } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ListHeader from '../../components/ListHeader';
import Separator from '../../components/Separator';
import { Text, View } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';
import ky from 'ky';

function useApps() {
    const { kollabToken, API_PREFIX, state: { userState } } = useContext(AuthContext);
    const { userToken } = userState;

    const [apps, setApps] = useState([]);

    const url = `${API_PREFIX}/kollab/apps`;

    useEffect(() => {
        (async () => {
            const res = await ky.get(url, {
                headers: { 'Authorization': `Bearer ${kollabToken}` }
            });

            const state = await res.json();
            console.info(state);

            setApps(state.items);
        })();
    }, []);

    return apps;
}

function AppsListItemView({ item, onTap }) {
    const navigation = useNavigation();

    return <TouchableOpacity onPress={() => onTap(item)}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 10 }}>
            {item.logo_url &&
                <Image style={{ height: 40, width: 40, borderRadius: 6, resizeMode: 'contain', backgroundColor: 'white' }} source={{ uri: item.logo_url }} />
            }
            <View style={{ flex: 1, marginLeft: 12 }}>
                <Text style={{ paddingBottom: 4, ...Fonts.subtitle }}>{item.name}</Text>
                {item.subtitle &&
                    <Text style={{ ...Fonts.badge }}>{item.subtitle}</Text>
                }
            </View>
        </View>
    </TouchableOpacity>
}

export default function AppsScreen({ route, navigation }) {
    const { state: { themeState, userState }, actions: { setApp, fetchApps } } = React.useContext(AuthContext);
    const apps = useApps();

    const title = 'Select App';

    async function onTap(item) {
        await setApp(item.id);

        navigation.goBack();
    }

    return <FlatList
        data={apps}
        ListHeaderComponent={() => <ListHeader title={title} />}
        keyExtractor={each => each.id}
        ItemSeparatorComponent={each => <Separator />}
        renderItem={({ item }) => <AppsListItemView item={item} collection={route.name} onTap={onTap} />}
    />
}
