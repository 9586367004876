import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import * as Notifications from 'expo-notifications';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { AuthProvider } from './state/AuthContext';
import { useRef } from 'react';
import { useEffect } from 'react';
import { setLocale } from './i18n';

import './setup';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default function KollabApp(appId: String, hasAppSwitcher = false, locale = 'en') {
  setLocale(locale);

  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const [notification, setNotification] = useState(false);

  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <ActionSheetProvider>
        <AuthProvider appId={appId} locale={locale} hasAppSwitcher={hasAppSwitcher}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} appId={appId} />
            <StatusBar />
          </SafeAreaProvider>
        </AuthProvider>
      </ActionSheetProvider>
    );
  }
}
