import React from 'react';
import { Card } from 'react-native-paper';
import { useNavigation } from "@react-navigation/native";
import * as FileSystem from 'expo-file-system';
import { useThemeColor, View } from '../../components/Themed';
import { PinboardModel } from './PinboardTypes';

interface PinboardListItemProps {
    board: PinboardModel,
    parentId?: string
}

export default function PinboardListItem({ board, parentId }: PinboardListItemProps) {
    const backgroundColor = useThemeColor({}, 'backgroundLight');

    const navigation = useNavigation();

    let cover;
    if (board.items.length === 0) {
        cover = <View style={{ height: 150, backgroundColor }} />;
    } else {
        cover = <Card.Cover style={{ height: 150 }} source={{ uri: board.thumbnailURL }} />;
    }

    return <Card onPress={() => navigation.push('PinboardDetail', { id: board.id, parentId })}>
        {cover}
        <Card.Title title={board.name} />
    </Card>
}
