import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Button } from 'react-native-elements';

import { Icon } from './Themed';

export default function DrawerButton() {
  const navigation = useNavigation();
  console.log('drawer');
  return <Button
    onPress={() => navigation.toggleDrawer()}
    icon={<Icon name="menu" size={30} />}
    type="clear"
  />
}
