import React, { useContext, useEffect } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import Constants from "expo-constants";
import { Text, View, Icon } from '../../components/Themed';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import Fonts from '../../constants/Fonts';
import ListHeader from "../../components/ListHeader";
import Separator from "../../components/Separator";
import { AuthContext } from "../../state/AuthContext";
import { DocumentsContext } from "./DocumentsContext";
import { FlatList } from "react-native-gesture-handler";

function Item({ folder }) {
    const navigation = useNavigation();
    const { actions: { getPrimaryColor } } = useContext(AuthContext);

    const { folder_name, date } = folder;

    return (<TouchableOpacity onPress={() => navigation.navigate('FolderDetail', {
        folderId: folder.id
    })}>
        <View style={{ flexDirection: "row", alignItems: 'center', paddingHorizontal: 12, paddingVertical: 10 }}>
            <Icon name="folder" size={50} color={getPrimaryColor()} />
            <View style={{ flex: 1, marginLeft: 12 }}>
                <Text style={{ ...Fonts.subtitle }}>{folder_name}</Text>
                <Text style={{ ...Fonts.badge }}>{date}</Text>
            </View>
        </View>

    </TouchableOpacity>);
};

export default function DocumentList({ route, navigation }) {
    const { actions: { getScreenThemeData, getFolders } } = React.useContext(AuthContext);

    const theme = getScreenThemeData(route.name);

    const folders = getFolders();

    useFocusEffect(() => {
        navigation.dangerouslyGetParent().setOptions({
            headerTitle: theme.title
        });
    });

    return <FlatList
        ListHeaderComponent={() => <ListHeader title={theme.subtitle} />}
        data={folders}
        ItemSeparatorComponent={Separator}
        keyExtractor={each => each.folder_name}
        renderItem={({ item }) => <Item folder={item} />}
    />
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: Constants.statusBarHeight,
        marginHorizontal: 16
    }
});
