import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Share,
    StyleSheet,
    View as DefaultView
} from "react-native";
import { Text, View, Icon } from '../../components/Themed';

import WebView from 'react-native-webview';
import { Input, Button, Overlay, Divider } from 'react-native-elements';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { PinboardContext } from './PinboardContext';
import Strings from '../../constants/Strings';
import { LocalWorkaroundWebview } from '../../components/LocalWorkaroundWebview';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import Fonts from '../../constants/Fonts';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as FileSystem from 'expo-file-system';
import { PinModel } from './PinboardTypes';

export default function PinDetailScreen({ route, navigation }) {
    const { boardId, itemId } = route.params;

    const { actions: { getPinData, deletePin, renamePin, setPinNotes } } = useContext(PinboardContext);

    const pin: PinModel = getPinData(boardId, itemId);

    if (!pin) {
        return <Text>...</Text>
    }

    let url = pin.url;

    console.info('PinDetailScreen', route.params, pin, url);

    const { showActionSheetWithOptions } = useActionSheet();
    const [title, setTitle] = React.useState(pin.title);

    const [visible, setVisible] = useState(false);

    const [notes, setNotes] = useState(pin.notes || '');

    async function share() {
        const url = pin.url;
        const result = await Share.share({
            message: url,
            url
        });
    }

    function openRenamePinModal() {
        setVisible(!visible);
    }

    const toggleOverlay = () => {
        setVisible(!visible);
    };

    function cancelRename() {
        setTitle(pin.title);
        toggleOverlay();
    }

    function confirmRename() {
        renamePin(boardId, itemId, title);
        toggleOverlay();
    }

    function updateNotes() {
        setPinNotes(itemId, notes);
    }

    function onDeletePin() {
        navigation.goBack();
        deletePin(boardId, itemId);
    }

    function showMenu() {
        const RENAME = Strings.pinboard.change_title;
        const DELETE = Strings.pinboard.delete_pin;
        const CANCEL = Strings.cancel;
        const options = [RENAME, DELETE, CANCEL];
        const destructiveButtonIndex = 1;
        const cancelButtonIndex = 2;

        showActionSheetWithOptions(
            {
                options,
                cancelButtonIndex,
                destructiveButtonIndex
            },
            buttonIndex => {
                switch (options[buttonIndex]) {
                    case RENAME: return openRenamePinModal();
                    case DELETE: return onDeletePin();
                    case CANCEL: return;
                }
            },
        );
    }

    useEffect(() => {
        navigation.setOptions({
            headerTitle: pin.title,
            headerRight: () => (
                <DefaultView style={{ flexDirection: 'row' }}>
                    <Button
                        disabled={!pin.isSynced}
                        onPress={share}
                        icon={<Icon name="share" size={30} />}
                        type="clear"

                    />
                    <Button
                        onPress={showMenu}
                        icon={<Icon name="dots-vertical" size={30} />}
                        type="clear"
                    />
                </DefaultView>
            )
        });
    });

    return <View style={{ flex: 1 }}>
        <KeyboardAwareScrollView contentContainerStyle={{ flex: 1, flexDirection: 'column' }}>
            <View style={{ flex: 5 }}>
                <LocalWorkaroundWebview uri={url}></LocalWorkaroundWebview>
            </View>
            <View style={{ flex: 1, padding: 12, backgroundColor: 'white' }}>
                <TextInput style={{ ...Fonts.text, height: 120 }} multiline={true} placeholder='Notizen' value={notes} onChangeText={setNotes} onEndEditing={updateNotes}></TextInput>
            </View>
        </KeyboardAwareScrollView>
        <Overlay overlayStyle={{ padding: 0 }} isVisible={visible} onBackdropPress={toggleOverlay}>
            <View style={{ minWidth: 300 }}>
                <View style={{ padding: 12 }}>
                    <Input placeholder='Titel' value={title} onChangeText={setTitle} />
                </View>
                <Divider></Divider>
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <View style={{ flex: 1 }}>
                        <Button type="clear" buttonStyle={{ padding: 12 }}
                            title="Abbrechen" onPress={() => cancelRename()} />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button buttonStyle={{ padding: 12 }} type="clear"
                            title="Bestätigen" onPress={() => confirmRename()} />
                    </View>
                </View>
            </View>
        </Overlay>
    </View>
}