import React, { useContext, useEffect, useState } from 'react';
import {
    StyleSheet
} from "react-native";
import { Text, View, Icon } from '../../components/Themed';
import { useFocusEffect } from '@react-navigation/native';
import { Input, Button, Overlay, Divider } from 'react-native-elements';

import { FlatGrid } from 'react-native-super-grid';
import ListHeader from '../../components/ListHeader';
import { PinboardContext } from './PinboardContext';
import { AuthContext } from '../../state/AuthContext';
import Fonts from '../../constants/Fonts';
import PinboardListItem from './PinboardListItem';

export default function PinboardListScreen({ route, navigation }) {
    const { actions: { fetch, getBoards, createBoard } } = useContext(PinboardContext);
    const { actions: { getScreenThemeData } } = React.useContext(AuthContext);
    const [lastFetch, setLastFetch] = useState<Date | null>(null);

    const theme = getScreenThemeData(route.name);

    const boards = getBoards();

    const [newTitle, setNewTitle] = useState('');
    const [visible, setVisible] = useState(false);

    const toggleOverlay = () => {
        setVisible(!visible);
    };

    function cancelCreate() {
        setNewTitle('');
        toggleOverlay();
    }

    function confirmCreate() {
        createBoard({ name: newTitle });
        setNewTitle('');
        toggleOverlay();
    }

    useEffect(() => {
        if (!lastFetch) {
            fetch();
            setLastFetch(new Date());
            return;
        }

        const time = new Date();
        if ((time.getTime() - lastFetch.getTime()) / 1000 > 5) {
            fetch();
            setLastFetch(time);
        }
    });

    useFocusEffect(() => {
        navigation.dangerouslyGetParent().setOptions({
            headerTitle: theme.title,
            headerRight: () => (
                <Button
                    disabled={boards === undefined}
                    onPress={toggleOverlay}
                    icon={<Icon name="plus" size={30} />}
                    type="clear"
                />
            )
        });

        return () => {
            navigation.dangerouslyGetParent().setOptions({
                headerRight: undefined
            });
        }
    });

    return (
        <View style={{ flex: 1 }}>
            {boards === undefined && <Text style={{ padding: 20 }}>Loading...</Text>}
            {boards !== undefined && <FlatGrid
                ListHeaderComponent={() => <ListHeader title="Meine Hausideen." />}
                itemDimension={140}
                data={boards}
                style={styles.gridView}
                renderItem={({ item, index }) => (
                    <PinboardListItem board={item} />
                )}
            />}
            <Overlay overlayStyle={{ padding: 0, backgroundColor: 'transparent' }} isVisible={visible} onBackdropPress={toggleOverlay}>
                <View style={{ minWidth: 300, borderRadius: 6 }}>
                    <View style={{ padding: 12, backgroundColor: 'transparent' }}>
                        <View style={{ alignItems: 'center', marginBottom: 12 }}>
                            <Text style={{ ...Fonts.title, marginBottom: 6 }}>Neue Pinnwand erstellen</Text>
                            <Text style={{ ...Fonts.text, textAlign: 'center' }}>Vergeben Sie einen Namen (z.B. Küche, Bad, Schlafzimmer, ...):</Text>
                        </View>
                        <Input key='pinboard_name' placeholder='Pinnwand Name' value={newTitle} onChangeText={setNewTitle} />
                    </View>
                    <Divider></Divider>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        <View style={{ flex: 1 }}>
                            <Button type="clear" buttonStyle={{ padding: 12 }}
                                title="Abbrechen" onPress={() => cancelCreate()} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Button buttonStyle={{ padding: 12 }} type="clear"
                                title="Erstellen" onPress={() => confirmCreate()} />
                        </View>
                    </View>
                </View>
            </Overlay>
        </View>
    );
}

const styles = StyleSheet.create({
    gridView: {
        marginTop: 0,
        flex: 1,
    }
});
