import { log } from "../../services/logs";
import { Board, Pin, CreateBoard, CreatePin, PinboardModel, CreateSpace, AssetCreds } from "./PinboardTypes";
import { v4 as createUUID } from 'uuid';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';

export interface PinboardState {
    boards?: Board[];
}

export class BoardStoreModel {
    state: PinboardState;
    creds: AssetCreds

    constructor(state: PinboardState, assetCreds: AssetCreds) {
        this.state = state;
        this.creds = assetCreds;
    }

    get boards() {
        return this.state.boards?.map(each => new PinboardModel(each, this.creds));
    }

    getBoard(id: string) {
        console.info('getBoard', id);

        if (!this.boards) {
            return undefined;
        }

        for (const board of this.boards) {
            if (board.id === id) {
                return board;
            }

            const { spaces = [] } = board;

            for (const space of spaces) {
                if (space.id === id) {
                    return space;
                }
            }
        }

        return undefined;
    }

    getPin(boardId: string, itemId: string) {
        console.info('getPin', boardId, itemId);

        const board = this.getBoard(boardId);

        return board?.items.find(each => each.id === itemId);
    }

    createBoard({ name }: CreateBoard) {
        if (!this.state.boards) {
            return;
        }

        const board: Board = {
            id: createUUID(),
            name,
            date: (new Date).toISOString(),
            items: [],
            spaces: []
        };

        console.info('createBoard', board);

        const boards = [board, ...this.state.boards];

        const newState: PinboardState = { boards };

        return newState;
    }

    renameBoard(id: string, name: string) {
        if (!this.state.boards) {
            return;
        }

        const boards = this.state.boards;

        for (const board of boards) {
            if (board.id === id) {
                board.name = name;
                const newState: PinboardState = { boards }
                return newState;
            }

            const { spaces = [] } = board;

            for (const space of spaces) {
                if (space.id === id) {
                    space.name = name;
                    const newState: PinboardState = { boards }
                    return newState;
                }
            }
        }

        return undefined;
    }

    deleteBoard(id: string) {
        if (!this.state.boards) {
            return;
        }

        const boards = this.state.boards.filter(each => each.id !== id);

        for (const board of boards) {
            if (!board.spaces) continue;
            board.spaces = board.spaces.filter(each => each.id !== id);
        }

        const newState: PinboardState = { boards };

        return newState;
    }

    setPinProperty(itemId: string, property: string, value: any) {
        console.info('setPinProperty', itemId, property, value);

        if (!this.state.boards) {
            return;
        }

        const { boards } = this.state;

        for (const { spaces = [], items } of boards) {
            for (const { items } of spaces) {
                for (const item of items) {
                    if (item.id === itemId) {
                        item[property] = value;
                        const newState: PinboardState = { boards };
                        return newState;
                    }
                }
            }

            for (const item of items) {
                if (item.id === itemId) {
                    item[property] = value;
                    const newState: PinboardState = { boards };
                    return newState;
                }
            }
        }
    }

    async createPin(boardId: string, { title = '', imageUrl, linkUrl, thumbnailUrl }: CreatePin) {
        console.log('createPin', boardId);

        if (!this.state.boards) {
            return;
        }

        const id = createUUID();

        const date = (new Date()).toISOString();

        let newPin: Pin;
        if (imageUrl) {
            const filePath = `${id}.jpeg`;
            const savedUrl = `${FileSystem.documentDirectory}${filePath}`;

            const { uri } = await ImageManipulator.manipulateAsync(imageUrl, [
                { resize: { width: 1000 } }
            ]);

            await FileSystem.copyAsync({ from: uri, to: savedUrl });
            newPin = {
                id,
                title,
                date,
                filePath
            };
        } else {
            newPin = {
                id,
                title,
                date,
                url: linkUrl,
                thumbnailUrl
            };
        }

        const boards = this.state.boards.map(each => {
            if (each.id !== boardId) {
                each.spaces = (each.spaces || []).map(space => {
                    if (space.id !== boardId) {
                        return space;
                    }

                    return {
                        ...space,
                        items: [newPin].concat(space.items)
                    };
                });

                return each;
            }

            return {
                ...each,
                items: [newPin].concat(each.items)
            };
        });

        const newState: PinboardState = { boards };

        return newState;
    }

    deletePin(boardId: string, itemId: string) {
        console.info('deletePin', boardId, itemId);

        if (!this.state.boards) {
            return;
        }

        const boards = this.state.boards.map(each => {
            const items = each.items.filter(each => each.id !== itemId);

            let { spaces = [] } = each;

            spaces = spaces.map(space => {
                const items = space.items.filter(each => each.id !== itemId);

                return {
                    ...space,
                    items
                }
            });

            return {
                ...each,
                spaces,
                items
            };
        });

        const newState: PinboardState = { boards };

        return newState;
    }

    createSpace({ name, boardId }: CreateSpace) {
        console.info('createSpace', name, boardId);

        if (!this.state.boards) {
            return;
        }

        const date = (new Date()).toISOString();

        const space: Board = {
            id: createUUID(),
            date,
            name,
            items: []
        };

        const boards = this.state.boards.map(each => {
            if (each.id !== boardId) {
                return each;
            }

            const spaces = each.spaces || [];

            return {
                ...each,
                spaces: [space, ...spaces]
            };
        });

        const newState: PinboardState = { boards };

        return newState;
    }

    getPinsPendingUpload() {
        if (!this.state.boards) {
            return;
        }

        let pins: Pin[] = [];

        for (const board of this.state.boards) {
            for (const pin of board.items) {
                if (pin.filePath) {
                    pins = [...pins, pin];
                }
            }

            if (board.spaces) {
                for (const space of board.spaces) {
                    for (const pin of space.items) {
                        if (pin.filePath) {
                            pins = [...pins, pin];
                        }
                    }
                }
            }
        }

        return pins;
    }
}

export function migratePinboards(oldBoards: Board[]) {
    function migrateUrl(url?: string) {
        if (!url) {
            return { url };
        }

        if (url.indexOf('file://') == -1) {
            return { url };
        }

        return {
            url: null,
            filePath: url.substring(url.lastIndexOf('/') + 1)
        };
    }

    const boards = oldBoards.map(board => ({
        ...board,
        date: board.date || (new Date()).toISOString(),
        spaces: (board.spaces || []).map(space => ({
            ...space,
            items: space.items.map(item => ({
                ...item,
                ...migrateUrl(item.url)
            }))
        })),
        items: board.items.map(item => ({
            ...item,
            date: item.date || (new Date()).toISOString(),
            ...migrateUrl(item.url)
        }))
    })) as Board[];

    return boards;
}

