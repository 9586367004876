import * as React from 'react';
import { View } from 'react-native';
import { Icon } from './Themed';

export default function BackIcon() {
    return <View
      style={{
        marginLeft: 6,
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: 18,
      }}
    >
      <Icon name='keyboard-arrow-left' size={36} color='white' />
    </View>
  }