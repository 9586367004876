import React, { useContext, useState } from 'react';
import { Alert, Linking } from 'react-native';
import { Input } from 'react-native-elements';
import InputScrollView from 'react-native-input-scroll-view';
import { View } from '../../components/Themed';
import { AuthContext } from '../../state/AuthContext';
import { SafeAreaView } from 'react-native-safe-area-context';
import { HeaderView } from './LoginScreen';
import { PrimaryButton, TextButton } from '../../components/Button';
import PasswordInput from './PasswordInput';

function SignupButton({ title = 'Login', onPress, isLoading }) {
    return <View style={{ marginBottom: 6 }}>
        <PrimaryButton title={title} onPress={onPress} loading={isLoading} />
    </View>
}

function LegalButton({ title = 'Forgot Login?', url }) {
    const {actions: {getPrimaryColor}} = useContext(AuthContext);

    return <TextButton title={title} titleStyle={{color: getPrimaryColor(), fontSize: 14}} onPress={() => Linking.openURL(url)} />
}

function SignupInputGroup() {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');

    const { state: { themeState }, actions: { signUp } } = React.useContext(AuthContext);
    const { signup: { signup_button_title, privacy_link_title, email_label, password_label, privacy_link } } = themeState;

    async function submit() {
        setIsLoading(true);
        const success = await signUp({ email, password });
        setIsLoading(false);

        if (!success) {
            Alert.alert(
                'Registrierung fehlgeschlagen',
                'Versuchen Sie es erneut.',
                [
                    { text: "OK", onPress: () => console.log("OK Pressed") }
                ],
                { cancelable: false }
            );
        }
    }

    return <View style={{paddingHorizontal: 12}}>
        <Input
            placeholder={email_label || 'Email'}
            keyboardType='email-address'
            value={email}
            onChangeText={setEmail}
        />
        <PasswordInput passwordLabel={password_label} password={password} setPassword={setPassword} />
        <SignupButton title={signup_button_title} onPress={submit} isLoading={isLoading} />
        <LegalButton title={privacy_link_title} url={privacy_link} />
    </View>
}

export default function SignupScreen() {
    return <SafeAreaView style={{ flex: 1 }}>
        <InputScrollView>
            <View style={{ flexDirection: 'column' }}>
                <HeaderView />
                <SignupInputGroup />
            </View>
        </InputScrollView>
    </SafeAreaView>;
}