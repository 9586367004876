import React, { useState, useEffect } from 'react';

const GenericContext = React.createContext();

function GenericProvider({ children }) {
    const [data, setData] = useState({});
    const initialFields = {};

    function set(field, value) {
        console.info('set', field, value, data);

        let newData = {...data};
        console.info('data1', newData);

        newData[field] = value;
        setData(newData);

        console.info('data2', newData);
    }

    function get(field) {
        return data[field];
    }

    function getAll() {
        return {
            ...initialFields,
            ...data
        };
    }

    function use(field, initial) {
        console.info('GenericProvider', 'use', field, initial);
        const existing = get(field);

        initialFields[field] = initial;

        return [existing ? existing : initial, (newValue) => set(field, newValue)];
    }

    return <GenericContext.Provider value={{ data, get, set, use, getAll }}>
        {children}
    </GenericContext.Provider>
}

export { GenericContext, GenericProvider };