import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthContext } from '../../state/AuthContext';
import { Button } from 'react-native-elements';
import { Icon } from '../../components/Themed';
import PlaceholderScreen from './PlaceholderScreen';
import DrawerButton from '../../components/DrawerButton';

export default function PlaceholderNavigator({ route, navigation }) {
  console.log(route.name, route.params);

  const { actions: { signOut, getScreenThemeData } } = React.useContext(AuthContext);

  const tab = getScreenThemeData(route.name);

  const Stack = createStackNavigator();

  return <Stack.Navigator>
    <Stack.Screen
      name="Placeholder"
      component={PlaceholderScreen}
      options={{
        headerTitle: tab.title,
        headerLeft: () => ( <DrawerButton /> )
      }}
    />
  </Stack.Navigator>
}
