import React, { useContext, useState } from 'react';
import { Alert, Image, Linking } from 'react-native';
import { Input, Button } from 'react-native-elements';
import InputScrollView from 'react-native-input-scroll-view';
import { View } from '../../components/Themed';
import { AuthContext } from '../../state/AuthContext';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { PrimaryButton, TextButton } from '../../components/Button';
import { Text, Icon } from "../../components/Themed";
import PasswordInput from './PasswordInput';
import i18n from '../../i18n';

export function HeaderView() {
    return <View>
        <HeaderImageView />
        <View style={{ backgroundColor: 'transparent', flexDirection: 'row', justifyContent: 'center', top: -52 }}>
            <LogoView />
        </View>
    </View>
}

function HeaderImageView() {
    const navigation = useNavigation();
    const { state: { themeState } } = useContext(AuthContext);
    const { login: { background_image } } = themeState;

    return <View style={{ backgroundColor: 'white', height: 220, paddingBottom: 4, paddingTop: 4 }}>
        <Image style={{ resizeMode: 'cover', height: '100%', width: '100%' }} source={{ uri: background_image }} />
        <View style={{ backgroundColor: 'transparent', position: 'absolute', top: 12, left: 6 }}>
            <Icon name='keyboard-arrow-left' type='material' size={40} color='white' onPress={() => navigation.goBack()} />
        </View>
    </View>
}

function LogoView() {
    const { state: { themeState } } = useContext(AuthContext);
    const { logo_url } = themeState;

    return <View style={{
        backgroundColor: 'white', width: 100, height: 100, padding: 12
    }}>
        <Image source={{ uri: logo_url }} style={{
            resizeMode: 'contain',
            width: '100%',
            height: '100%'
        }}></Image>
    </View>
}

function LoginButton({ title = 'Login', onPress, isLoading }) {
    return <View style={{ marginBottom: 6 }}>
        <PrimaryButton title={title} onPress={onPress} loading={isLoading} />
    </View>
}

function ForgotLoginButton({ title = 'Forgot Login?', url }) {
    return <TextButton title={title} onPress={() => Linking.openURL(url)} />
}

function LoginInputGroup() {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');

    const { state: { themeState }, actions: { signIn } } = React.useContext(AuthContext);

    async function submit() {
        setIsLoading(true);
        const success = await signIn({ email, password });
        setIsLoading(false);

        if (!success) {
            Alert.alert(
                i18n('login_failed_title'),
                i18n('login_failed_body'),
                [
                    { text: "OK", onPress: () => console.log("OK Pressed") }
                ],
                { cancelable: false }
            );
        }
    }

    const { login: { login_button_title, forgot_login_button_title, email_label, password_label, forgot_login_url } } = themeState;

    return <View style={{ paddingHorizontal: 12 }}>
        <Input
            placeholder={email_label || 'Email'}
            keyboardType='email-address'
            textContentType='username'
            value={email}
            onChangeText={setEmail}
        />
        <PasswordInput passwordLabel={password_label} password={password} setPassword={setPassword} />
        <LoginButton title={login_button_title} onPress={submit} isLoading={isLoading} />
        <ForgotLoginButton title={forgot_login_button_title} url={forgot_login_url} />
    </View>
}

export default function LoginScreen() {
    return <SafeAreaView style={{ flex: 1 }}>
        <InputScrollView>
            <View style={{ flexDirection: 'column' }}>
                <HeaderView />
                <LoginInputGroup />
            </View>
        </InputScrollView>
    </SafeAreaView>;
}