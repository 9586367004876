import * as React from 'react';
import { DataTable } from 'react-native-paper';
import { View } from './Themed';

const optionsPerPage = [2, 3, 4];

export default function Table({ columns, rows }) {
    console.info('###Table', columns, rows);
    const [page, setPage] = React.useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);

    React.useEffect(() => {
        setPage(0);
    }, [itemsPerPage]);

    return (
        <View style={{ backgroundColor: 'white' }}>
            <DataTable>
                <DataTable.Header>
                    {columns.map(each => <DataTable.Title>{each}</DataTable.Title>)}
                </DataTable.Header>

                {rows.map(each => <DataTable.Row>
                    {each.map(cell => <DataTable.Cell>{cell}</DataTable.Cell>)}
                </DataTable.Row>
                )}

                <DataTable.Pagination
                    page={page}
                    numberOfPages={1}
                    onPageChange={(page) => setPage(page)}
                //   optionsPerPage={optionsPerPage}
                //   itemsPerPage={itemsPerPage}
                //   setItemsPerPage={setItemsPerPage}
                //   showFastPagination
                //   optionsLabel={'Rows per page'}
                />
            </DataTable>
        </View>
    );
}