import { useFocusEffect } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as React from 'react';
import * as Notifications from 'expo-notifications';
import { Platform, SectionList } from 'react-native';
import { Image, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import ListHeader from '../../components/ListHeader';
import SectionHeader from '../../components/SectionHeader';
import Separator from '../../components/Separator';
import { Icon, Text, useThemeColor, View as ThemedView } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';
import { CardItem } from '../cards/CardList';
import { ContactItem } from '../contacts/ContactList';
import { DocumentItem } from '../documents/FolderDetail';
import { useEffect, useRef, useState } from 'react';

function DashboardSection({ icon, title, children }) {
    const headerColor = useThemeColor({}, 'textLight');

    return <View style={{ marginBottom: 12 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 12 }}>
            <Icon name={icon} size={30} color={headerColor} />
            <Text style={{ ...Fonts.subtitle, marginLeft: 10, color: headerColor }}>{title}</Text>
        </View>
        {children}
    </View>
}

function ProgressView({ title, progress }) {
    const progressBarGreen = useThemeColor({}, 'progressBar');
    const textColor = useThemeColor({}, 'text');

    return <ThemedView style={{ padding: 12 }}>
        <Text style={{ ...Fonts.subtitle }}>{title}</Text>
        <View style={{ paddingVertical: 10 }}>
            <Text style={{ ...Fonts.hero, color: progressBarGreen }}>{progress}%</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flexDirection: 'row', flex: 1, backgroundColor: useThemeColor({}, 'backgroundLight'), height: 4, marginRight: 12 }}>
                    <View style={{ flex: progress, backgroundColor: progressBarGreen, height: '100%' }} />
                    <View style={{ flex: 100 - progress }} />
                </View>
                <Image style={{ width: 70, height: 70, tintColor: textColor }} source={require('../../../assets/images/key.png')} />
            </View>
        </View>
    </ThemedView>
}

function RecentDocuments({ items }) {
    return <SeparatedList items={items} component={(item) => <DocumentItem document={item} />} />
}

function SeparatedList({ items, component }) {
    return <>
        {items.map((each, idx) =>
            <>
                {component(each)}
                { idx < items.length - 1 && <Separator />}
            </>)
        }
    </>
}

function RecentContacts({ items }) {
    return <SeparatedList items={items} component={(item) => <ContactItem contact={item} />} />
}

export default function DashboardScreen({ route, navigation }) {
    const { actions: { getScreenThemeData, getScreenUserData, mapCardBadges, setPushToken } } = React.useContext(AuthContext);

    const theme = getScreenThemeData(route.name);
    const data = getScreenUserData(route.name);
    
    const sections = {
        progress: 'Projektfortschritt'.toUpperCase(),
        recent_documents: 'Letzte Dokumente'.toUpperCase(),
        recent_contacts: 'Letzte Kontakte'.toUpperCase()
    };

    useFocusEffect(() => {
        navigation.dangerouslyGetParent().setOptions({
            headerTitle: theme.title
        });
    });

    useEffect(() => {
        registerForPushNotificationsAsync().then(token => setPushToken(token));
    }, []);

    const cardSections = data.cards.map(each => {
        return {
            title: each.name,
            data: each.cards.map(mapCardBadges)
        };
    });

    return <SectionList
        ListHeaderComponent={() => <ProgressSection title={sections.progress} progress={data.progress} status={data.progress_message} />}
        sections={cardSections}
        ItemSeparatorComponent={Separator}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => <CardItem key={item.id} card={item} />}
        renderSectionHeader={({ section: { title } }) => <SectionHeader title={title} />}
    />
}

function ProgressSection({ title, progress, status }) {
    return <DashboardSection icon="check_circle_outline" title={title}>
        <ProgressView title={status} progress={progress} />
    </DashboardSection>
}

async function registerForPushNotificationsAsync() {
    let token;
    if (Constants.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        
        if (finalStatus !== 'granted') {
            console.info('Failed to get push token for push notification!');
            return;
        }
        
        token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
        // alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return token;
}