
import en from './en.json';
import de from './de.json';

const locales = {
    en,
    de
}

let activeLocale = 'en';

export function setLocale(locale: string) {
    activeLocale = locale;
}

export function locale() {
    return activeLocale;
}

export default function i18n(key: string, locale = activeLocale) {
    return locales[locale][key];
}
