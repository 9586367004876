import React from 'react';
import { Button } from 'react-native-elements';
import { Icon } from './Themed';

export default function ShareButton({ onPress }) {
    return <Button
        onPress={onPress}
        icon={<Icon name="share" size={30} />}
        type="clear"
    />
}