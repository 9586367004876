import React, { useContext, useRef, useState } from 'react';
import { Button } from 'react-native-elements';
import { AuthContext } from '../state/AuthContext';

export function PrimaryButton({title, onPress, ...props}) {
    const {actions: {getPrimaryColor}} = useContext(AuthContext);
    const primaryColor = getPrimaryColor();

    return <Button buttonStyle={{backgroundColor: primaryColor}} raised={true} title={title} onPress={onPress} {...props}/>
}

export function SecondaryButton({title, onPress, ...props}) {
    const {actions: {getPrimaryColor}} = useContext(AuthContext);
    const primaryColor = getPrimaryColor();

    return <Button buttonStyle={{borderColor: 'white'}} titleStyle={{color: primaryColor}} type="outline" raised={true} title={title} onPress={onPress} {...props}/>
}

export function TextButton({title, onPress, color, ...props}) {
    const {actions: {getPrimaryColor}} = useContext(AuthContext);

    const titleColor = color ?? getPrimaryColor();

    return <Button titleStyle={{ color: titleColor }} title={title} onPress={onPress} type="clear" {...props} />
}