import KollabApp from "./src/KollabApp";
import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://2d566fbc8c4b47b080814017868a4c8b@o502654.ingest.sentry.io/5585211',
//   enableInExpoDevelopment: true,
//   debug: true
});

export default function App() {
    return KollabApp('badisa', false);
}
