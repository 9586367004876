const tintColorLight = '#006AB3';
const tintColorDark = 'rgb(54, 133, 247)';

export default {
  light: {
    text: '#000',
    textLight: 'rgb(133, 133, 133)',
    background: '#fff',
    backgroundLight: 'rgb(232, 232, 232)',
    backgroundLight2: 'rgb(242, 242, 242)',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    progressBar: '#68C700'
  },
  dark: {
    text: '#fff',
    textLight: 'rgb(143, 143, 143)',
    background: '#000',
    backgroundLight: 'rgb(37, 37, 37)',
    backgroundLight2: 'rgb(27, 27, 27)',
    tint: tintColorDark,
    tabIconDefault: 'rgb(133, 133, 133)',
    tabIconSelected: tintColorDark,
    progressBar: '#68C700'
  },
};
