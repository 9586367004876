import * as React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import LoginScreen from "../screens/auth/LoginScreen";
import OnboardingScreen from "../screens/onboarding/OnboardingScreen";
import WelcomeScreen from "../screens/welcome/WelcomeScreen";
import SignupScreen from '../screens/auth/SignupScreen';

export default function WelcomeNavigator() {
    const Stack = createStackNavigator();

    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Onboarding" component={OnboardingScreen} />
            <Stack.Screen name="Welcome" component={WelcomeScreen} />
            <Stack.Screen name="SignIn" component={LoginScreen} />
            <Stack.Screen name="SignUp" component={SignupScreen} />
        </Stack.Navigator>
    );
}
