import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { FlatList, Image, RefreshControl } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ListHeader from '../../components/ListHeader';
import Separator from '../../components/Separator';
import { Text, View } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';

function SpacesListItemView({ item, onTap }) {
    const navigation = useNavigation();

    return <TouchableOpacity onPress={() => onTap(item)}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 10 }}>
            {item.logo_url &&
                <Image style={{ height: 40, width: 40, borderRadius: 6, resizeMode: 'contain', backgroundColor: 'white' }} source={{ uri: item.logo_url }} />
            }
            <View style={{ flex: 1, marginLeft: 12 }}>
                <Text style={{ paddingBottom: 4, ...Fonts.subtitle }}>{item.title}</Text>
                {item.subtitle &&
                    <Text style={{ ...Fonts.badge }}>{item.subtitle}</Text>
                }
            </View>
        </View>
    </TouchableOpacity>
}

export default function SpacesScreen({ route, navigation }) {
    const { projectId, state: { themeState, userState }, actions: { setProject, fetchState } } = React.useContext(AuthContext);

    const items = userState.spaces;
    const title = 'Select Space';

    async function onTap(item) {
        await setProject(item.id);

        navigation.goBack();
    }

    return <FlatList
        data={items}
        ListHeaderComponent={() => <ListHeader title={title} />}
        keyExtractor={each => each.id}
        ItemSeparatorComponent={each => <Separator />}
        renderItem={({ item }) => <SpacesListItemView item={item} collection={route.name} onTap={onTap} />}
    />
}
