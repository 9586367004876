import * as React from 'react';
import WebView from "react-native-webview";

// Local file URLs error on Android
// Adapted from here:
// https://github.com/react-native-webview/react-native-webview/issues/656#issuecomment-561067913

export function LocalWorkaroundWebview({ uri }) {
    const [renderedOnce, setRenderedOnce] = React.useState(false);

    const updateSource = () => {
        setRenderedOnce(true);
    };

    return <WebView
        startInLoadingState={true}
        allowFileAccess={true}
        allowUniversalAccessFromFileURLs={true}
        allowFileAccessFromFileURLs={true}
        style={{ backgroundColor: 'transparent' }}
        onMessage={event => {
            console.log(JSON.parse(event.nativeEvent.data));
        }}
        onLoad={updateSource}
        source={renderedOnce ? { uri } : undefined} originWhitelist={['*']}
    ></WebView>
}