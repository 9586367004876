import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FlatList, Image, RefreshControl } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ListHeader from '../../components/ListHeader';
import Separator from '../../components/Separator';
import { Text, View } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';

export function GenericListItemView({ item, collection }) {
    const navigation = useNavigation();

    return <TouchableOpacity onPress={() => navigation.navigate('GenericDetail', { path: [item.id], collection })}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 10 }}>
            {item.thumbnail_url &&
                <Image style={{ height: 50, width: 50, borderRadius: 25, resizeMode: 'cover' }} source={{ uri: item.thumbnail_url }} />
            }
            <View style={{ flex: 1, marginLeft: 12 }}>
                <Text style={{ paddingBottom: 4, ...Fonts.subtitle }}>{item.title}</Text>
                <Text style={{ ...Fonts.badge }}>{item.subtitle}</Text>
            </View>
        </View>
    </TouchableOpacity>
}

export default function GenericListView({ route, navigation }) {
    const { state: { userState: { active_space } }, actions: { getScreenThemeData, getScreenUserData, fetchState } } = React.useContext(AuthContext);
    const [refreshing, setRefreshing] = useState(false)
    const [items, setItems] = useState(getItems());

    const theme = getScreenThemeData(route.name);

    function getItems() {
        return getScreenUserData(route.name);
    }

    const startRefresh = async () => {
        setRefreshing(true);

        await fetchState();
        const items = getItems();
        setItems(items);

        setRefreshing(false);
    };

    useFocusEffect(() => {
        navigation.dangerouslyGetParent().setOptions({
            headerTitle: theme.title
        });
    });

    useEffect(() => {
        const items = getItems();
        setItems(items);
        setRefreshing(false);
    }, [active_space]);

    return <FlatList
        data={items}
        ListHeaderComponent={() => theme.subtitle ? <ListHeader title={theme.subtitle} /> : null}
        keyExtractor={each => each.id}
        ItemSeparatorComponent={each => <Separator />}
        renderItem={({ item }) => <GenericListItemView item={item} collection={route.name} />}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={startRefresh} />}
    />
}
