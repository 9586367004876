import * as React from 'react';
import { AsyncStorage, useColorScheme } from 'react-native';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthContext } from '../state/AuthContext';
import SplashScreen from '../screens/SplashScreen';
import { ThemeProvider } from 'react-native-elements';
import DrawerRootNavigator from './HomeNavigator';
import WelcomeNavigator from './WelcomeNavigator';

const Stack = createStackNavigator();

export default function Navigation({ navigation, colorScheme, appId }: { colorScheme: ColorSchemeName, appId: String }) {
    const { state, actions: { init, dispatch } } = React.useContext(AuthContext);

    React.useEffect(() => {
        const bootstrapAsync = async () => {
            await init(appId);
        };

        bootstrapAsync();
    }, []);

    return (
        <ThemeProvider useDark={colorScheme === 'dark'}>
            <NavigationContainer theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
                <Stack.Navigator screenOptions={{ headerShown: false }}>
                    {state.isLoading ? (
                        <Stack.Screen name="Splash" component={SplashScreen} />
                    ) : state.userToken == null ? (
                        <Stack.Screen
                            name="Welcome"
                            component={WelcomeNavigator}
                            options={{
                                animationTypeForReplace: state.isSignout ? 'pop' : 'push',
                            }}
                        />
                    ) : (
                        <Stack.Screen name="Home" component={DrawerRootNavigator} />
                    )}
                </Stack.Navigator>
            </NavigationContainer>
        </ThemeProvider>
    );
}
