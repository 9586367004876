import React, { useCallback, useContext, useEffect, useLayoutEffect } from "react";
import { StyleSheet, SectionList, TouchableOpacity, View, Image } from "react-native";
import Constants from "expo-constants";
import { Text, View as ViewThemed, Icon, LightBackgroundView, useThemeColor } from '../../components/Themed';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import Fonts from '../../constants/Fonts';
import ListHeader from "../../components/ListHeader";
import SectionHeader from "../../components/SectionHeader";
import Separator from "../../components/Separator";
import { AuthContext } from "../../state/AuthContext";

export function CardItem({ card }) {
    const navigation = useNavigation();
    const green = useThemeColor({}, 'progressBar');
    const backgroundColor = card.is_completed ? useThemeColor({}, 'backgroundLight2') : useThemeColor({}, 'background');

    const { name, badges = [] } = card;

    return (<TouchableOpacity onPress={() => navigation.navigate('CardDetail', {
        cardId: card.id
    })}>
        <ViewThemed style={{ flexDirection: 'row', alignItems: 'center', backgroundColor }}>
            {card.contact &&
                <View style={{ paddingLeft: 12, paddingVertical: 6 }}>
                    <Image style={{ height: 44, width: 44, borderRadius: 22, resizeMode: 'cover' }} source={{ uri: card.contact.image_url }} />
                </View>
            }
            <View style={{ flex: 1, paddingVertical: 14, paddingHorizontal: 12 }}>
                <Text style={{ ...Fonts.subtitle }}>{name}</Text>
                {badges.length > 0 && <View style={{ flexDirection: 'row', paddingTop: 6 }}>
                    {badges.map(each => <ItemInfo key={each.id} id={each.id} icon={each.icon} label={each.label} />)}
                </View>}
            </View>
            {card.is_completed && <View style={{ paddingHorizontal: 12 }}>
                <Icon name="check-circle" size={40} color={green} />
            </View>}
        </ViewThemed>

    </TouchableOpacity>);
};

function ItemInfo({ id, icon, label }) {
    return <View style={{ flexDirection: 'row', alignItems: 'center', paddingRight: 6 }}>
        <Icon name={icon} size={24} />
        {label && <Text style={{ paddingHorizontal: 2 }}>{label}</Text>}
    </View>
}

export default function CardsList({ route, navigation }) {
    const { state: { userState }, actions: { getScreenThemeData, getScreenUserData, mapCardBadges } } = React.useContext(AuthContext);

    const theme = getScreenThemeData(route.name);
    const userData = getScreenUserData(route.name);

    const [listData, setListData] = React.useState(fetchListData());

    function fetchListData() {
        console.info("fetch");
        return userData.map(each => {
            return {
                title: each.name,
                data: each.cards.map(mapCardBadges)
            };
        });
    }

    useFocusEffect(
        useCallback(() => {
            navigation.dangerouslyGetParent().setOptions({
                headerTitle: theme.title
            });

            setListData(fetchListData());
        }, [navigation])
    );

    return <SectionList
        ListHeaderComponent={() => <ListHeader title={theme.subtitle} />}
        sections={listData}
        ItemSeparatorComponent={Separator}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => <CardItem key={item.id} card={item} />}
        renderSectionHeader={({ section: { title } }) => <SectionHeader title={title} />}
    />
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: Constants.statusBarHeight,
        marginHorizontal: 16
    }
});
