import React, { Component, useContext, useEffect, useState } from 'react';
import {
    StyleSheet,
    Platform,
    View as DefaultView
} from "react-native";
import { Text, View, Icon } from '../../components/Themed';
import * as ImagePicker from 'expo-image-picker';
import { Button, Overlay, Input, Divider } from 'react-native-elements';
import { useActionSheet } from '@expo/react-native-action-sheet'

import { SectionGrid } from 'react-native-super-grid';
import { PinboardContext } from './PinboardContext';
import Strings from '../../constants/Strings';
import { getLinkPreview, getPreviewFromContent } from 'link-preview-js';
import PinboardListItem from './PinboardListItem';
import PinListItem from './PinListItem';
import { PinboardModel } from './PinboardTypes';

export default function PinboardDetailScreen({ route, navigation }) {
    console.info('PinboardDetailScreen', route.params);
    const { id } = route.params;

    const { showActionSheetWithOptions } = useActionSheet();
    const { actions: { getBoardData, deleteBoard, renameBoard, createPin, createSpace } } = useContext(PinboardContext);

    const board = getBoardData(id) as PinboardModel;

    if (!board) {
        return <Text>...</Text>
    }

    const { items, spaces = [] } = board;

    const [title, setTitle] = React.useState(board.name);
    const [newLink, setNewLink] = React.useState('');
    const [newSpaceTitle, setNewSpaceTitle] = React.useState('');

    const [visible, setVisible] = useState(false);
    const [addLinkVisible, setAddLinkVisible] = useState(false);
    const [createSpaceVisible, setCreateSpaceVisible] = useState(false);

    function addPin(url) {
        createPin(id, { imageUrl: url });
    }

    function onDeleteBoard() {
        function onConfirm() {
            navigation.goBack();
            deleteBoard(id);
        }

        showConfirmMenu({
            title: Strings.pinboard.confirm_delete_pin_message,
            actionTitle: Strings.pinboard.delete_board,
            onConfirm
        });
    }

    function openRenameBoardModal() {
        setVisible(!visible);
    }

    function cancelRename() {
        setTitle(board.name);
        toggleOverlay();
    }

    function confirmRename() {
        toggleOverlay();
        renameBoard(id, title);
    }

    const toggleOverlay = () => {
        setVisible(!visible);
    };

    const toggleAddLink = () => {
        setAddLinkVisible(!addLinkVisible);
    };

    const toggleCreateSpace = () => {
        setCreateSpaceVisible(!createSpaceVisible);
    }

    function cancelCreateSpace() {
        setNewSpaceTitle('');
        toggleCreateSpace();
    }

    function confirmCreateSpace() {
        const name = newSpaceTitle;
        createSpace({ name, boardId: id });

        setNewSpaceTitle('');
        toggleCreateSpace();
    }

    function cancelAddLink() {
        setNewLink('');
        toggleAddLink();
    }

    async function confirmAddLink() {
        try {
            console.info('getLinkPreview', newLink);

            const result = await getLinkPreview(newLink, {
                imagesPropertyType: "og",
                headers: {
                    "user-agent": "googlebot"
                }
            });

            const { title, images } = result;
            const thumbnailUrl = images.length > 0 ? images[0] : undefined;

            const itemTitle = `${title}`;

            createPin(id, { title: itemTitle, linkUrl: newLink, thumbnailUrl });
        } catch (err) {
            console.info(err);
            createPin(id, { title: newLink, linkUrl: newLink });
        }

        setNewLink('');
        toggleAddLink();
    }

    async function ensureCameraRollPermissions() {
        if (Platform.OS !== 'web') {
            const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== 'granted') {
                alert(Strings.pinboard.camera_roll_permission_denied);
            }
        }
    }

    async function ensureCameraPermissions() {
        if (Platform.OS !== 'web') {
            const { status } = await ImagePicker.requestCameraPermissionsAsync();
            if (status !== 'granted') {
                alert(Strings.pinboard.camera_permission_denied);
            }
        }
    }

    async function openCamera() {
        await ensureCameraPermissions();

        let result = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            quality: 0.7
        });

        console.log(result);

        if (!result.cancelled) {
            addPin(result.uri);
        }
    }

    const pickImage = async () => {
        await ensureCameraRollPermissions();

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            quality: 0.7
        });

        console.log(result);

        if (!result.cancelled) {
            addPin(result.uri);
        }
    };

    function showCreateMenu() {
        const TAKE_PHOTO = Strings.pinboard.take_photo;
        const SELECT_PHOTO = Strings.pinboard.choose_photo;
        const ADD_LINK = Strings.pinboard.add_link;
        const CREATE_SPACE = Strings.pinboard.create_space;
        const CANCEL = Strings.cancel;
        const options = board.spaces ?
            [TAKE_PHOTO, SELECT_PHOTO, ADD_LINK, CREATE_SPACE, CANCEL]
            : [TAKE_PHOTO, SELECT_PHOTO, ADD_LINK, CANCEL];
        const cancelButtonIndex = options.length - 1;

        showActionSheetWithOptions(
            {
                options,
                cancelButtonIndex
            },
            buttonIndex => {
                switch (options[buttonIndex]) {
                    case TAKE_PHOTO: return openCamera();
                    case SELECT_PHOTO: return pickImage();
                    case ADD_LINK: return toggleAddLink();
                    case CREATE_SPACE: return toggleCreateSpace();
                    case CANCEL: return;
                }
            },
        );
    }

    function showMenu() {
        const RENAME = Strings.pinboard.change_title;
        const DELETE = Strings.pinboard.delete_board;
        const CANCEL = Strings.cancel;
        const options = [RENAME, DELETE, CANCEL];
        const destructiveButtonIndex = 1;
        const cancelButtonIndex = 2;

        showActionSheetWithOptions(
            {
                options,
                destructiveButtonIndex,
                cancelButtonIndex
            },
            buttonIndex => {
                switch (options[buttonIndex]) {
                    case RENAME: return openRenameBoardModal();
                    case DELETE: return onDeleteBoard();
                    case CANCEL: return;
                }
            },
        );
    }

    function showConfirmMenu({ title, actionTitle, onConfirm }) {
        const CONFIRM = actionTitle;
        const CANCEL = Strings.cancel;
        const options = [CONFIRM, CANCEL];
        const destructiveButtonIndex = 0;
        const cancelButtonIndex = 1;

        showActionSheetWithOptions(
            {
                options,
                title,
                destructiveButtonIndex,
                cancelButtonIndex
            },
            buttonIndex => {
                switch (options[buttonIndex]) {
                    case CONFIRM: return onConfirm();
                    case CANCEL: return;
                }
            },
        );
    }

    useEffect(() => {
        navigation.setOptions({
            headerTitle: board.name,
            headerRight: () => (
                <DefaultView style={{ flexDirection: 'row' }}>
                    <Button
                        onPress={showCreateMenu}
                        icon={<Icon name="plus" size={30} />}
                        type="clear"
                    />
                    <Button
                        onPress={showMenu}
                        icon={<Icon name="dots-vertical" size={30} />}
                        type="clear"
                    />
                </DefaultView>

            )
        });
    });

    const sections = [
        {
            id: 'spaces',
            title: '',
            data: spaces
        },
        {
            id: 'pins',
            title: '',
            data: items
        }
    ]

    return (
        <>
            <SectionGrid
                itemDimension={140}
                style={styles.gridView}
                sections={sections}
                renderItem={({ item, section, index }) => (
                    section.id === 'spaces' ?
                        <PinboardListItem board={item} />
                        : <PinListItem item={item} boardId={board.id} />
                )}
            />
            <Overlay overlayStyle={{ padding: 0 }} isVisible={visible} onBackdropPress={toggleOverlay}>
                <View style={{ minWidth: 300 }}>
                    <View style={{ padding: 12 }}>
                        <Input placeholder='Titel' value={title} onChangeText={setTitle} />
                    </View>
                    <Divider></Divider>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        <View style={{ flex: 1 }}>
                            <Button type="clear" buttonStyle={{ padding: 12 }}
                                title="Abbrechen" onPress={() => cancelRename()} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Button buttonStyle={{ padding: 12 }} type="clear"
                                title="Bestätigen" onPress={() => confirmRename()} />
                        </View>
                    </View>
                </View>
            </Overlay>
            <Overlay overlayStyle={{ padding: 0 }} isVisible={addLinkVisible} onBackdropPress={toggleAddLink}>
                <View style={{ minWidth: 300 }}>
                    <View style={{ padding: 12 }}>
                        <Input placeholder='Link eingeben' value={newLink} onChangeText={setNewLink} />
                    </View>
                    <Divider></Divider>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        <View style={{ flex: 1 }}>
                            <Button type="clear" buttonStyle={{ padding: 12 }}
                                title="Abbrechen" onPress={() => cancelAddLink()} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Button buttonStyle={{ padding: 12 }} type="clear"
                                title="Hinzufügen" onPress={() => confirmAddLink()} />
                        </View>
                    </View>
                </View>
            </Overlay>
            <Overlay overlayStyle={{ padding: 0 }} isVisible={createSpaceVisible} onBackdropPress={toggleCreateSpace}>
                <View style={{ minWidth: 300 }}>
                    <View style={{ padding: 12 }}>
                        <Input placeholder='Bereich anlegen' value={newSpaceTitle} onChangeText={setNewSpaceTitle} />
                    </View>
                    <Divider></Divider>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        <View style={{ flex: 1 }}>
                            <Button type="clear" buttonStyle={{ padding: 12 }}
                                title="Abbrechen" onPress={() => cancelCreateSpace()} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Button buttonStyle={{ padding: 12 }} type="clear"
                                title="Anlegen" onPress={() => confirmCreateSpace()} />
                        </View>
                    </View>
                </View>
            </Overlay>
        </>
    );
}

const styles = StyleSheet.create({
    gridView: {
        marginTop: 0,
        flex: 1,
    }
});
