import * as React from 'react';
import { useState } from 'react';
import { Image } from 'react-native';
import { CheckBox, Input, Button, Overlay, Divider } from 'react-native-elements';

import { ScrollView } from 'react-native-gesture-handler';
import Markdown from 'react-native-markdown-display';
import { Text, View, Icon, useThemeColor } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';
import { ContactItem } from '../contacts/ContactList';
import { DocumentItem } from '../documents/FolderDetail';

export default function CardDetail({ route }) {
    const { cardId } = route.params;
    const { actions: { getCard } } = React.useContext(AuthContext);

    const card = getCard(cardId);

    return <ScrollView>
        <Header card={card} />
        <CardInfo card={card} />
        <Description text={card.description} />
        {card.checklists.length > 0 && <Checklists checklists={card.checklists} />}
        {card.documents.length > 0 && <DocumentsSection title='Dokumente' documents={card.documents} />}
    </ScrollView>
}

function Header({ card }) {
    return <View style={{ height: 280 }}>
        <Image style={{ resizeMode: 'cover', height: '100%' }} source={{ uri: card.image_url }} />
        <View style={{ padding: 6, position: 'absolute', bottom: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <Text style={{ color: 'white', ...Fonts.title }}>{card.name}</Text>
        </View>
    </View>
}

function CardInfo({ card }) {
    return <View style={{ flexDirection: 'row', paddingVertical: 12 }}>
        {card.contact && <ContactInfo contact={card.contact} />}
        {card.date && <DateInfo date={card.date} />}
    </View>
}

function ContactInfo({ contact }) {
    return <View style={{ flex: 1 }}>
        <ContactItem contact={contact} />
    </View>
}

function DateInfo({ date }) {
    return <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 6 }}>
        <Icon name="calendar-month-outline" size={36} />
        <Text style={{ paddingLeft: 6, ...Fonts.subtitle }}>{date}</Text>
    </View>
}

function Description({ text }) {
    const color = useThemeColor({}, 'text');

    return <View style={{ paddingHorizontal: 12, paddingVertical: 12 }}>
        <Markdown style={{ body: { color, ...Fonts.text } }}>{text}</Markdown>
    </View>
}

function Checklists({ checklists }) {
    return <View style={{}}>
        {checklists.map(each => <Checklist key={each.name} checklist={each} />)}
    </View>
}

function Checklist({ checklist }) {
    return <View>
        <Text style={{ padding: 6, ...Fonts.subtitle }}>{checklist.name}</Text>
        {checklist.items.map(each => <ChecklistItem item={each} />)}
    </View>
}

function ChecklistItem({ item }) {
    const { actions: { getPrimaryColor, getTodoCompleted, setTodoCompleted } } = React.useContext(AuthContext);

    const initialChecked = getTodoCompleted(null, item.id);

    const [checked, setChecked] = useState(initialChecked);
    const [visible, setVisible] = useState(false);

    const needsConfirmation = !!item.needs_confirmation;

    const confirmationBackground = useThemeColor({}, 'backgroundLight');
    const confirmationTitle = item.confirmation_title || '';
    const confirmationBody = item.confirmation_text || '';
    const confirmationCancel = item.confirmation_cancel_button || 'Cancel';
    const confirmationConfirm = item.confirmation_confirm_button || 'Continue';

    const backgroundColor = needsConfirmation ? confirmationBackground : undefined;

    async function toggle() {
        if (needsConfirmation) {
            if (checked) {
                return true;
            }

            setVisible(true);
            return;
        }

        const newChecked = !checked;

        await setTodoCompleted(null, item.id, newChecked);

        setChecked(newChecked);
    }

    function cancel() {
        console.info('cancel');
        setVisible(false);
    }

    async function confirm() {
        setVisible(false);
        setChecked(true);

        await setTodoCompleted(null, item.id, true);
    }

    return <View>
        <CheckBox
            textStyle={{ ...Fonts.subtitle }}
            containerStyle={{
                backgroundColor,
                borderWidth: 0
            }}
            checkedColor={getPrimaryColor()}
            title={<>
                <Text style={{ ...Fonts.text }}>{item.name} </Text>
                {item.confirmation_text && <Icon name="comment-check-outline" size={22} color={useThemeColor({}, 'text')} />
                }
            </>}
            checked={checked}
            onPress={() => toggle()}
        />
        <Overlay overlayStyle={{ padding: 0, backgroundColor: 'transparent' }} isVisible={visible} onBackdropPress={cancel}>
            <View style={{ minWidth: 300, borderRadius: 6 }}>
                <View style={{ padding: 12, backgroundColor: 'transparent' }}>
                    <View style={{ alignItems: 'center', marginBottom: 12 }}>
                        <Text style={{ ...Fonts.title, marginBottom: 6 }}>{confirmationTitle}</Text>
                        <Text style={{ ...Fonts.text, textAlign: 'center' }}>{confirmationBody}</Text>
                    </View>
                </View>
                <Divider></Divider>
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <View style={{ flex: 1 }}>
                        <Button type="clear" buttonStyle={{ padding: 12 }}
                            title={confirmationCancel} onPress={cancel} />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button buttonStyle={{ padding: 12 }} type="clear"
                            title={confirmationConfirm} onPress={() => confirm()} />
                    </View>
                </View>
            </View>
        </Overlay>
    </View>
}

function DocumentsSection({ title, documents }) {
    return <View style={{ paddingVertical: 12 }}>
        <Text style={{ paddingVertical: 6, paddingHorizontal: 6, ...Fonts.subtitle }}>{title}</Text>
        <DocumentsList documents={documents} />
    </View>
}

function DocumentsList({ documents }) {
    return <View>
        {documents.map(each => <DocumentItem key={each.id} document={each} />)}
    </View>
}
