
export default {
    hero: {
        fontFamily: 'System',
        fontSize: 36,
        fontWeight: '300'
    },
    title: {
        fontFamily: 'System',
        fontSize: 20,
        fontWeight: '400'
    },
    subtitle: {
        fontFamily: 'System',
        fontWeight: '400',
        fontSize: 18
    },
    text: {
        fontFamily: 'System',
        fontSize: 18
    },
    badge: {
        fontFamily: 'System',
        fontSize: 16
    }
};