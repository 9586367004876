import * as React from 'react';
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import { Icon, Text } from '../../components/Themed';
import { Linking, View } from "react-native";
import SectionHeader from '../../components/SectionHeader';
import { PinboardContext } from '../pinboard/PinboardContext';
import Constants from 'expo-constants';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'react-native';
import { AuthContext } from '../../state/AuthContext';

function LogoView({ uri }) {
    return <Image style={{ height: 34, width: 34, borderRadius: 4, resizeMode: 'contain', backgroundColor: 'white' }} source={{ uri }} />
}

function ProjectSwitcherItem({ item, theme }) {
    const label = "Switch Project";

    return (
        <DrawerItem
            label={getLabel()}
            icon={getIcon}
            onPress={getOnPress()}
        />
    )
}

function CustomDrawerItem({ item, theme, userState, actions, navigation, ...rest }) {
    // const navigation = useNavigation();

    const action = item.url.slice(1);
    const type = item.url.slice(1);
    const { signOut } = actions;

    function getLabel() {
        if (type === 'switch_space') {
            return userState.active_space.title;
        }

        if (type === 'account_details') {
            if (!userState) {
                return 'Loading...'
            }

            if (!userState.account) {
                return 'Demo Nutzer';
            }

            return userState.account.email;
        }

        return item.title || 'Undefined'
    }

    function getIcon({ size, color }) {
        if (action === 'switch_space') {
            const logo_url = userState.active_space.logo_url;

            if (!logo_url) {
                return null;
            }

            return <LogoView uri={logo_url} />
        }

        return item.icon ? <Icon name={item.icon} size={size} color={color} /> : undefined;
    }

    function getLabelStyle() {
        if (action === 'switch_space') {
            const logo_url = userState.active_space.logo_url;

            return logo_url ? { fontSize: 18, left: -15 } : { fontSize: 19 };
        }

        return null;
    }

    function getOnPress() {
        switch (action) {
            case 'switch_space':
                return () => navigation.navigate('SpacesScreen', {});
            case 'change_password':
                if (userState) {
                    return () => Linking.openURL(userState.account?.change_password_url ?? '');
                }
            case 'logout': return () => signOut();
        }

        if (item.url && item.url.startsWith('http')) {
            return () => Linking.openURL(item.url);
        }

        return undefined;
    }

    return <DrawerItem
        label={getLabel()}
        icon={getIcon}
        onPress={getOnPress()}
        labelStyle={getLabelStyle()}
    />
}

function DeveloperSection() {
    const { actions: { resetPinboards } } = React.useContext(PinboardContext);

    function reset() {
        resetPinboards();
    }

    return <View key="developer">
        <SectionHeader title="Demo Modus" />
        <DrawerItem label="Inhalte Zurücksetzen" onPress={reset} />
    </View>
}

function AppVersionSection() {
    const { name, version } = Constants.manifest;
    const label = `Release v${version}`;
    return <View key="version">
        <DrawerItem label={label} onPress={() => { }} />
    </View>
}

function AppSwitcherItem() {
    const navigation = useNavigation();
    const { state: { themeState } } = React.useContext(AuthContext);
    const { name, logo_url } = themeState;
    const action = 'switch_app';

    const labelStyle = { fontSize: 18, left: -15 };
    const icon = <LogoView uri={logo_url} />;
    const onPress = () => navigation.navigate('AppsScreen', {});

    return <DrawerItem
        label={name}
        icon={() => icon}
        onPress={onPress}
        labelStyle={labelStyle}
    />
}

export default function CustomDrawerContent({ theme, navigation, userState, actions, ...props }) {
    const { hasAppSwitcher } = React.useContext(AuthContext);

    const sidebarConfig = theme.sidebar;

    function SectionHeader({ title }) {
        return <View style={{ padding: 10 }}>
            <Text style={{ fontSize: 18 }}>{title}</Text>
        </View>
    }

    return (
        <DrawerContentScrollView {...props}>
            {hasAppSwitcher && <AppSwitcherItem />}
            {sidebarConfig.map((section) => <View key={section.title}>
                <SectionHeader title={section.title} />
                {section.items.map((item, idx) =>
                    <CustomDrawerItem key={idx} item={item} theme={sidebarConfig} userState={userState} actions={actions} navigation={navigation} />
                )}
            </View>)
            }
            {isInternalUser(userState) && <DeveloperSection />}
            <AppVersionSection />
        </DrawerContentScrollView>
    );
}

function isInternalUser(userState) {
    if (!userState.account) return false;

    if (userState.account.email.indexOf('bien-zenker') > -1) {
        return true;
    };

    if (userState.account.email.indexOf('blackbeltlabs.com') > -1) {
        return true;
    };

    return false;
}