import * as FileSystem from 'expo-file-system';
import { BoardStoreModel, PinboardState } from "./PinboardStore";
import { AssetCreds } from './PinboardTypes';

export class Uploader {
    isUploading = false;

    async uploadPending(state: PinboardState, creds: AssetCreds) {
        console.info('ask upload');

        if (this.isUploading) {
            console.info('already uploading');
            return { err: new Error('busy uploading') };
        }

        const store = new BoardStoreModel(state, creds);
        const pinsPending = store.getPinsPendingUpload();

        if (!pinsPending) {
            console.info('not ready to upload');
            return { err: new Error('not initialized') };
        }

        if (pinsPending.length === 0) {
            console.info('nothing to upload');
            return { err: null };
        }

        this.isUploading = true;

        const [pin] = pinsPending;
        const url = `https://assets.bitmio.workers.dev/bienzenker/${creds.prefix}/${pin.id}.jpeg?token=${creds.token}`;

        const localURL = `${FileSystem.documentDirectory}${pin.filePath}`;

        console.info('uploading', url, localURL);

        try {
            await FileSystem.uploadAsync(url, localURL, {
                headers: { 'Content-Type': 'image/jpeg' }
            });

            console.info('completed upload');

            const newState = store.setPinProperty(pin.id, 'filePath', undefined);

            this.isUploading = false;

            return { err: null, value: newState };
        } catch (err) {
            return { err };
        }
    }
}