import React, { useContext, useEffect } from 'react';
import { Platform, Share } from "react-native";
import WebView from 'react-native-webview';
import PDFReader from 'rn-pdf-reader-js'
import ShareButton from '../../components/ShareButton';
import { View } from '../../components/Themed';
import { AuthContext } from '../../state/AuthContext';

export default function DocumentDetailScreen({ route, navigation }) {
    const { actions: { getDocument } } = useContext(AuthContext);

    let { url, name } = route.params;

    const { docId } = route.params;

    const useWebView = true;

    if (!url) {
        const document = getDocument(docId);
        url = document.url;
        name = document.name;
    }

    async function share() {
        const result = await Share.share({
            message: url,
            url
        });
    }

    useEffect(() => {
        navigation.setOptions({
            headerTitle: name,
            headerRight: () => <ShareButton onPress={share} />
        });
    });

    return <View style={{ flex: 1 }}>
        {Platform.OS === 'web' && <iframe width="100%" height="100%" src={url}></iframe>}
        {useWebView && <WebView
            // style={styles.container}
            source={{ uri: url }}
        />}
        {!useWebView && <PDFReader source={{ uri: url }} withPinchZoom={true}></PDFReader>
        }
    </View>
}