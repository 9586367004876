import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { FlatList, Image } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ListHeader from '../../components/ListHeader';
import Separator from '../../components/Separator';
import { Text, View } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';

export function ContactItem({ contact }) {
    const navigation = useNavigation();

    return <TouchableOpacity onPress={() => navigation.navigate('ContactDetail', { contactId: contact.id })}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 10 }}>
            <Image style={{ height: 50, width: 50, borderRadius: 25, resizeMode: 'cover' }} source={{ uri: contact.image_url }} />
            <View style={{ flex: 1, marginLeft: 12 }}>
                <Text style={{ paddingBottom: 4, ...Fonts.subtitle }}>{contact.name}</Text>
                <Text style={{ ...Fonts.badge }}>{contact.role}</Text>
            </View>
        </View>
    </TouchableOpacity>
}

export default function ContactList({ route, navigation }) {
    const { actions: { getContacts, getScreenThemeData } } = React.useContext(AuthContext);

    const theme = getScreenThemeData(route.name);

    const contacts = getContacts();

    useFocusEffect(() => {
        navigation.dangerouslyGetParent().setOptions({
            headerTitle: theme.title
        });
    });

    return <FlatList
        data={contacts}
        ListHeaderComponent={() => <ListHeader title={theme.subtitle} />}
        keyExtractor={each => each.role_id}
        ItemSeparatorComponent={each => <Separator />}
        renderItem={({ item }) => <ContactItem contact={item} />}
    />
}
