import React, { useState } from 'react';
import { View } from '../../components/Themed';
import { Input, Button } from 'react-native-elements';
import { Icon } from "../../components/Themed";

export default function PasswordInput({ passwordLabel, password, setPassword }) {
    const [showPassword, setShowPassword] = useState(false);

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    return <View style={{ flex: 1, flexDirection: 'row' }}>
        <Input
            placeholder={passwordLabel || 'Password'}
            textContentType='password'
            secureTextEntry={!showPassword}
            value={password}
            onChangeText={setPassword}
        />
        <View style={{ position: 'absolute', right: 0, top: -5 }}>
            <Button
                type="clear"
                style={{ backgroundColor: 'clear' }}
                containerStyle={{ backgroundColor: 'clear' }}
                icon={<Icon name={showPassword ? 'eye' : 'eye-off'} size={28} />}
                onPress={toggleShowPassword}
            />
        </View>
    </View>
}
