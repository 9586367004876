import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Modal, StyleSheet } from 'react-native';
import { FlatList, ScrollView, TouchableHighlight, TouchableOpacity } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon, Text, useThemeColor, View } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';
import { DocumentItem } from '../documents/FolderDetail';
import SvgExample, { ChartExample1, LineChartWidget, SvgExample2, SvgExample3, XAxisExample } from '../../components/Chart';
import { useNavigation, useRoute } from '@react-navigation/core';
import Table from '../../components/Table';
import { FAB } from 'react-native-paper';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import moment from 'moment';
import { Input } from 'react-native-elements';
import { PrimaryButton } from '../../components/Button';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { GenericContext, GenericProvider } from './Context';
import ky from 'ky';

function PropertyView({ icon, label, value, onTap }) {
    const color = onTap ? useThemeColor({}, 'tint') : useThemeColor({}, 'textLight');

    return <TouchableHighlight onPress={onTap}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingLeft: 12, paddingRight: 0, paddingVertical: 12 }}>
            <Icon name={icon} size={30} color={color} />
            <View style={{ flex: 1, marginLeft: 10 }}>
                <Text style={{ ...Fonts.text, marginBottom: 4 }}>{label}</Text>
                <Text style={{ ...Fonts.text, color: color }}>{value}</Text>
            </View>
        </View>
    </TouchableHighlight>
}

function TitleWidget({ item }) {
    return <View style={{ alignItems: 'center', paddingTop: 20, paddingBottom: 20 }}>
        <Text style={{ ...Fonts.hero }}>{item.title}</Text>
    </View>
}

function ChartWidget({ labels, datasets }) {
    return <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 12, paddingRight: 12, paddingVertical: 12 }}>
        <View style={{ height: 190, paddingLeft: 0, paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
            <LineChartWidget labels={labels} datasets={datasets} />
        </View>
    </View>
}

function TableWidget({ columns, rows }) {
    return <Table columns={columns} rows={rows} />
}

function CreateItemWidget({ id }) {
    const navigation = useNavigation();
    const route = useRoute();
    const { collection, path } = route.params;
    const modalPath = [...path, id];

    const { actions: { getPrimaryColor } } = useContext(AuthContext);
    const primaryColor = getPrimaryColor();

    const styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center'
        }
    });

    function onTap() {
        navigation.navigate('GenericForm', { collection, path: modalPath });
    }

    return <>
        <FAB
            style={{
                position: 'absolute',
                margin: 30,
                backgroundColor: primaryColor,
                right: 0,
                bottom: 20,
            }}
            small
            icon="plus"
            onPress={onTap}
        />
    </>
}

function PropertyValueView({ icon, label, value, onTap }) {
    const color = onTap ? useThemeColor({}, 'tint') : useThemeColor({}, 'textLight');

    return <TouchableHighlight onPress={onTap}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingLeft: 12, paddingRight: 0, paddingVertical: 12 }}>
            <Icon name={icon} size={30} color={color} />
            <Text style={{ ...Fonts.text, marginBottom: 4, marginLeft: 6, flex: 1 }}>{label}</Text>
            <Text style={{ ...Fonts.text, marginRight: 12, color: color }}>{value}</Text>
        </View>
    </TouchableHighlight>
}

function DateInputWidget({ id, label }) {
    const { use } = useContext(GenericContext);
    const [date, setDate] = use(id, new Date());
    const [open, setOpen] = React.useState(false);

    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmSingle = React.useCallback(
        (params) => {
            setOpen(false);
            setDate(params.date);
        },
        [setOpen, setDate]
    );

    return (
        <>
            <PropertyValueView
                icon="calendar"
                label={label}
                value={moment(date).format('YYYY-MM-DD')}
                onTap={() => setOpen(true)}
            />
            <DatePickerModal
                mode="single"
                visible={open}
                onDismiss={onDismissSingle}
                date={date}
                onConfirm={onConfirmSingle}
            />
        </>
    );
}

function TimeInputWidget({ id, icon = 'clock-outline', label }) {
    const { use } = useContext(GenericContext);
    const [time, setTime] = use(id, {
        hours: moment().hour(),
        minutes: moment().minutes()
    });

    const [open, setOpen] = React.useState(false);

    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmSingle = React.useCallback(
        ({ hours, minutes }) => {
            setOpen(false);
            setTime({ hours, minutes });
        },
        [setOpen, setTime]
    );

    return (
        <>
            <PropertyValueView
                icon={icon}
                label={label}
                value={moment({ hour: time.hours, minute: time.minutes }).format('HH:mm')}
                onTap={() => setOpen(true)}
            />
            <TimePickerModal
                visible={open}
                onDismiss={onDismissSingle}
                onConfirm={onConfirmSingle}
            />
        </>
    );
}

function TextInputWidget({ id, placeholder, icon, label, defaultValue = '', keyboardType = 'default' }) {
    const { use } = useContext(GenericContext);
    const [text, setText] = use(id, '');
    const color = useThemeColor({}, 'tint')

    return <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingLeft: 12, paddingRight: 0, paddingVertical: 12 }}>
        {icon && <Icon name={icon} size={30} color={color} />}
        <Text style={{ ...Fonts.text, marginBottom: 4, marginLeft: 6, flex: 0 }}>{label}</Text>
        <Input
            style={{ flex: 1, top: 4 }}
            placeholder={placeholder || 'Enter value'}
            value={text}
            onChangeText={setText}
            keyboardType={keyboardType}
        />
    </View>
}

function SubmitButtonWidget({ id, webhook, title = 'Submit' }) {
    const route = useRoute();
    const { collection, path } = route.params;
    const { getAll } = useContext(GenericContext);
    const { actions: { fetchState } } = useContext(AuthContext);
    const navigation = useNavigation();

    async function onPress() {
        const fields = getAll();
        console.info('form submit', getAll(), webhook);

        const data = {
            collection,
            path,
            id,
            ...fields
        };

        const result = await ky.post(webhook, { json: data }).json();

        await fetchState();

        navigation.goBack();
    }

    return <PrimaryButton title={title} onPress={onPress} />
}

function RecordsWidget({ id, icon, onTap, title, date, value }) {
    const navigation = useNavigation();
    const route = useRoute();
    const { collection, path } = route.params;
    const detailPath = [...path, id];

    const color = onTap ? useThemeColor({}, 'tint') : useThemeColor({}, 'textLight');

    return <TouchableHighlight onPress={() => navigation.push('GenericDetail', { collection, path: detailPath })}>
        <View style={{ flex: 1, flexDirection: 'row', paddingLeft: 12, paddingRight: 20, paddingTop: 6, paddingBottom: 6, justifyContent: 'space-between' }}>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <Icon name={icon} size={30} color={color} />
                <View style={{ flex: 1, marginLeft: 10 }}>
                    <Text style={{ ...Fonts.text, marginBottom: 4 }}>{title}</Text>
                    <Text style={{ ...Fonts.text, color }}>{value}</Text>
                </View>
            </View>
            <View style={{ flex: 0, flexDirection: 'column', justifyContent: 'flex-start', }}>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ ...Fonts.badge, color }}>{date}</Text>
                    <Icon name={'keyboard-arrow-right'} size={20} color={color} />
                </View>
            </View>
        </View>
    </TouchableHighlight>
}

function WidgetView({ item }) {
    console.info('widget', item);

    if (item.type === 'title') {
        return <TitleWidget item={item} />
    }

    if (item.type === 'property') {
        return <PropertyView icon="phone" label="Telefon" value={'123'} />;
    }

    if (item.type === 'records') {
        return <RecordsWidget {...item} />;
    }

    if (item.type === 'chart') {
        return <ChartWidget {...item} />;
    }

    if (item.type === 'table') {
        return <TableWidget {...item} />;
    }

    if (item.type === 'attachments') {
        return <DocumentsSection title={item.title} documents={item.files} />
    }

    if (item.type === 'text') {
        return <TextInputWidget {...item} />;
    }

    if (item.type === 'date') {
        return <DateInputWidget {...item} />;
    }

    if (item.type === 'time') {
        return <TimeInputWidget {...item} />;
    }

    if (item.type === 'submit') {
        return <SubmitButtonWidget {...item} />;
    }

    return <PropertyView icon="phone" label="Unknown" value={'Unknown'} />;
}

function DocumentsSection({ title, documents }) {
    return <View style={{ paddingVertical: 12 }}>
        <Text style={{ paddingVertical: 6, paddingHorizontal: 6, ...Fonts.subtitle }}>{title}</Text>
        <DocumentsList documents={documents} />
    </View>
}

function DocumentsList({ documents }) {
    return <View>
        {documents.map(each => <DocumentItem key={each.id} document={each} />)}
    </View>
}

export default function GenericDetailView({ route, navigation }) {
    const { actions: { getScreenUserData } } = React.useContext(AuthContext);

    const { collection, path } = route.params;

    let list = getScreenUserData(collection);
    let item;

    for (let itemId of path) {
        item = list.find(each => each.id === itemId);
        list = item.detail || [];
    }

    let data = item.detail;
    let title = item.title;

    const createWidget = data.find(each => each.type === 'create-item');
    const listWidgets = data.filter(each => each.type !== 'create-item');

    useEffect(() => {
        navigation.setOptions({
            headerTitle: title
        });
    });

    return <GenericProvider>
        <View style={{ flex: 1 }}>
            <FlatList
                data={listWidgets}
                keyExtractor={item => item.id}
                renderItem={({ item }) => <WidgetView item={item} />
                }
            />
            {createWidget && <CreateItemWidget {...createWidget} />}
        </View>
    </GenericProvider>
}
