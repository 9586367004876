import * as React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import NotFoundScreen from "../screens/NotFoundScreen";
import BottomTabNavigator from "./BottomTabNavigator";
import { Icon } from '../components/Themed';
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useWindowDimensions } from "react-native";
import { AuthContext } from '../state/AuthContext';
import CustomDrawerContent from '../screens/drawer/CustomDrawerContent';
import CardDetail from '../screens/cards/CardDetail';
import GenericDetail from '../screens/generic/GenericDetail';
import BackIcon from '../components/BackIcon';
import DocumentDetailScreen from '../screens/documents/DocumentDetail';
import DrawerButton from '../components/DrawerButton';
import FolderDetail from '../screens/documents/FolderDetail';
import ContactDetail from '../screens/contacts/ContactDetail';
import PinboardDetailScreen from '../screens/pinboard/PinboardDetailScreen';
import PinDetailScreen from '../screens/pinboard/PinDetailScreen';
import { PinboardProvider } from '../screens/pinboard/PinboardContext';
import SpacesScreen from '../screens/spaces/SpacesScreen';
import AppsScreen from '../screens/apps/AppsScreen';
import GenericFormView from '../screens/generic/GenericForm';

const Stack = createStackNavigator();

function HomeNavigator() {
    return (
        <Stack.Navigator mode="card" screenOptions={{ animationEnabled: true, cardStyle: { flex: 1 } }}>
            <Stack.Screen name="Home" component={BottomTabNavigator}
                options={{
                    headerLeft: () => <DrawerButton />,
                    title: ''
                }}
            />
            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
            <Stack.Screen options={{
                headerTransparent: true,
                headerTitle: '',
                headerBackImage: () => <BackIcon />,
                headerBackTitleVisible: false
            }} name="CardDetail" component={CardDetail} />
            <Stack.Screen name="GenericDetail" component={GenericDetail} />
            <Stack.Screen name="WidgetDetail" component={GenericDetail} />
            <Stack.Screen name="FolderDetail" component={FolderDetail} />
            <Stack.Screen name="DocumentDetail" component={DocumentDetailScreen} />
            <Stack.Screen options={{
                headerTitle: '',
                headerTransparent: true,
                headerBackImage: () => <BackIcon />,
                headerBackTitleVisible: false
            }} name="ContactDetail" component={ContactDetail} />
            <Stack.Screen name="PinboardDetail" component={PinboardDetailScreen} />
            <Stack.Screen name="PinDetail" component={PinDetailScreen} />
        </Stack.Navigator>
    );
}

const Drawer = createDrawerNavigator();

function DrawerRootNavigator() {
    const { state: { themeState, userState }, actions } = React.useContext(AuthContext);
    const { fetchState } = actions;

    React.useEffect(() => {
        const bootstrapAsync = async () => {
            await fetchState();
        };

        bootstrapAsync();
    }, []);
    const dimensions = useWindowDimensions();

    return <PinboardProvider>
        <Drawer.Navigator
            drawerType="front"
            // drawerType={dimensions.width >= 768 ? 'permanent' : 'front'}
            drawerContent={(props) => <CustomDrawerContent theme={themeState} userState={userState} actions={actions} {...props} />}
            header
        >
            <Drawer.Screen
                name="Root"
                component={HomeNavigator}
                options={{
                    drawerLabel: 'Home',
                    drawerIcon: ({ size, color }) => <Icon name="home" size={size} color={color} />
                }}
            />
        </Drawer.Navigator>
    </PinboardProvider>;
}

export default function LoggedInNavigator() {
    return <Stack.Navigator mode="modal">
        <Stack.Screen
            name="DrawerRoot"
            component={DrawerRootNavigator}
            options={{ headerShown: false, title: '' }}
        />
        <Stack.Screen
            name="SpacesScreen"
            component={SpacesScreen}
            options={{ title: 'Spaces' }}
        />
        <Stack.Screen
            name="AppsScreen"
            component={AppsScreen}
            options={{ title: 'Apps' }}
        />
        <Stack.Screen name="GenericForm" component={GenericDetail} />
    </Stack.Navigator>
}