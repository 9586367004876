import { useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import { ImageBackground, StyleSheet, Image, View } from 'react-native';
import { PrimaryButton, SecondaryButton, TextButton } from '../../components/Button';
import { Text, } from '../../components/Themed';
import { AuthContext } from '../../state/AuthContext';

function LogoView() {
    const { state: { themeState } } = useContext(AuthContext);
    const { logo_url } = themeState;

    return <View style={{
        backgroundColor: 'white', width: 120, height: 150, padding: 12
    }}>
        <Image source={{ uri: logo_url }} style={{
            top: 18,
            resizeMode: 'contain',
            width: '100%',
            height: '100%'
        }}></Image>
    </View>
}

function WelcomeTitle({ title = 'Welcome' }) {
    return <Text style={{ color: 'white', fontSize: 30, paddingBottom: 24, paddingTop: 12 }}>{title}</Text>
}

function LoginButton({ title = 'Login' }) {
    const navigation = useNavigation();

    return <View style={styles.buttonContainer}>
        <PrimaryButton
            title={title}
            onPress={() => navigation.push('SignIn')}
        />
    </View>
}

function ExploreButton({ title = 'Explore' }) {
    const { actions: { demo } } = useContext(AuthContext);

    const navigation = useNavigation();

    return <View style={styles.buttonContainer}>
        <SecondaryButton
            title={title}
            onPress={() => demo()}
        />
    </View>
}

function SignupButton({ title = 'Create Account' }) {
    const navigation = useNavigation();

    return <TextButton color="white" title={title} onPress={() => navigation.push('SignUp')} />
}

function ButtonsView() {
    const { state: { themeState } } = useContext(AuthContext);
    const { welcome: { hero_title, login_button_title, signup_button_title, discover_button_title } } = themeState;

    return <View style={{ padding: 12, marginBottom: 50, backgroundColor: 'rgba(52, 52, 52, 0.5)' }}>
        <WelcomeTitle title={hero_title} />
        <View>
            <LoginButton title={login_button_title} />
            {discover_button_title && <ExploreButton title={discover_button_title} />}
            <SignupButton title={signup_button_title} />
        </View>
    </View>
}

export default function WelcomeScreen({ route, navigation }) {
    const { state: { themeState } } = useContext(AuthContext);
    const { welcome: { background_image } } = themeState;

    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <ImageBackground source={{ uri: background_image }} style={styles.image}>
                <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <LogoView />
                    </View>
                    <ButtonsView />
                </View>
            </ImageBackground>
        </View>
    );
}

const styles = StyleSheet.create({
    image: {
        flex: 1,
        width: '100%'
        // aspectRatio: 1,
        // maxWidth: '100%'
    },
    buttonContainer: {
        marginBottom: 6
    }
});