import PlaceholderNavigator from './placeholder/PlaceholderNavigator';
import CardsList from './cards/CardList';
import DocumentList from './documents/DocumentsList';
import ContactList from './contacts/ContactList';
import DashboardScreen from './dashboard/DashboardScreen';
import PinboardListScreen from './pinboard/PinboardListScreen';
import GenericList from './generic/GenericList';

export function getNavigator(name: String) {
    switch (name) {
        case 'dashboard': return DashboardScreen;
        case 'card_list': return CardsList;
        case 'document_list': return DocumentList;
        case 'contact_list': return ContactList;
        case 'pinboard_list': return PinboardListScreen;
        case 'generic_list': return GenericList;
        default: return PlaceholderNavigator;
    }
}