import * as FileSystem from 'expo-file-system';

export interface AssetCreds {
    token: string;
    prefix: string
}

export interface Pin {
    id: string;
    title: string;
    date: string;

    // Link Pins:
    url?: string;
    thumbnailUrl?: string;

    // Image Pins:
    filePath?: string;
    imageUrl?: string;
    imageThumbnailUrl?: string;

    notes?: string;
}

export class PinboardModel {
    board: Board;
    creds: AssetCreds;

    constructor(board: Board, creds: AssetCreds) {
        this.board = board;
        this.creds = creds;
    }

    get id() {
        return this.board.id;
    }

    get name() {
        return this.board.name;
    }

    get items() {
        return this.board.items.map(each => new PinModel(each, this.creds));
    }

    get isSpace() {
        return this.board.spaces === undefined;
    }

    get thumbnailURL() {
        if (this.board.items.length === 0) {
            return undefined;
        }

        return this.items[0].thumbnailURL;
    }

    get spaces() {
        return this.board.spaces?.map(each => new PinboardModel(each, this.creds));
    }
}

export class PinModel {
    pin: Pin;
    creds: AssetCreds;

    constructor(pin: Pin, creds: AssetCreds) {
        this.pin = pin;
        this.creds = creds;
    }

    get id() {
        return this.pin.id;
    }

    get title(): string {
        return this.pin.title;
    }

    get notes(): string {
        return this.pin.notes || '';
    }

    get isSynced() {
        return this.pin.filePath === undefined;
    }

    get thumbnailURL(): string {
        if (this.pin.thumbnailUrl) {
            return this.pin.thumbnailUrl;
        }

        if (this.pin.url) {
            if (this.pin.url.indexOf('.jpg') > -1) {
                return this.pin.url;
            }
        }

        if (this.pin.filePath) {
            return this.url;
        }

        return `https://images.bitmio.com/bienzenker/${this.creds.prefix}/${this.pin.id}.jpeg?w=200`;
    }

    get url(): string {
        if (this.pin.url) {
            return this.pin.url;
        }

        if (this.pin.filePath) {
            return `${FileSystem.documentDirectory}${this.pin.filePath}`
        }

        return `https://images.bitmio.com/bienzenker/${this.creds.prefix}/${this.pin.id}.jpeg?w=1000`;
    }

    get uploadURL(): string {
        return `https://assets.bitmio.workers.dev/bienzenker/${this.creds.prefix}/${this.pin.id}.jpeg`;
    }
}

export interface Board {
    id: string;
    name: string;
    date: string;
    items: Pin[];

    spaces?: Board[]; // undefined for Space
}

export interface CreateBoard {
    name: string;
}

export interface CreateSpace {
    name: string;
    boardId: string;
}

export interface CreatePin {
    title: string;
    imageUrl?: string;
    linkUrl?: string;
    thumbnailUrl?: string;
}