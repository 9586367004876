import { useNavigation } from '@react-navigation/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, ImageBackground, Platform } from 'react-native';

export default function OnboardingScreen() {
    if (Platform.OS === 'web') {
        return <OnboardingScreenWeb />
    }

    const OnboardingScreenMobile = require('./OnboardingScreenNative').default;

    return <OnboardingScreenMobile />
}

function OnboardingScreenWeb() {
    const navigation = useNavigation();


    useEffect(() => {
        navigation.navigate('Welcome')
    });

    return <View></View>
}