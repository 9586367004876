import * as Sentry from "sentry-expo";

export function log(message, info) {
    const logObj = {
        ...info,
        message
    };

    const logString = JSON.stringify(logObj, null, 2);

    Sentry.captureMessage(logString);
}

export function logError(error) {
    Sentry.captureException(error);
}
