import * as React from 'react';
import { Text as DefaultText, View as DefaultView } from 'react-native';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { IconProps } from '@expo/vector-icons/build/createIconSet';

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function LightBackgroundView(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'backgroundLight');

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function Icon(props: IconProps<string>) {
  const {name, color, size, ...rest} = props;
  const themedColor = color || useThemeColor({}, 'text');

  function getFallbackIcon() {
    return <MaterialIcons name="error" size={size} color={themedColor} {...rest} />;
  }

  const altName = name.replace(/_/g, '-');

  if (MaterialIcons.glyphMap[name]) {
    return <MaterialIcons name={name} size={size} color={themedColor} {...rest} />
  } else if (MaterialCommunityIcons.glyphMap[name]) {
    return <MaterialCommunityIcons name={name} size={size} color={themedColor} {...rest} />
  } else if (MaterialIcons.glyphMap[altName]) {
    return <MaterialIcons name={altName} size={size} color={themedColor} {...rest} />
  } else if (MaterialCommunityIcons.glyphMap[altName]) {
    return <MaterialCommunityIcons name={altName} size={size} color={themedColor} {...rest} />
  }

  console.info('Missing icon', name, altName);

  return getFallbackIcon();
}