import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { FlatList, Image, Linking } from 'react-native';
import { Button } from 'react-native-elements';
import { ScrollView, TouchableHighlight, TouchableOpacity } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { PrimaryButton } from '../../components/Button';
import { Icon, Text, useThemeColor, View } from "../../components/Themed";
import Fonts from '../../constants/Fonts';
import { AuthContext } from '../../state/AuthContext';
import { ContactsContext } from './ContactsContext';

function ContactProperty({ icon, label, value, onTap }) {
    const color = onTap ? useThemeColor({}, 'tint') : useThemeColor({}, 'textLight');

    return <TouchableHighlight onPress={onTap}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingLeft: 12, paddingRight: 0, paddingVertical: 12 }}>
            <Icon name={icon} size={30} color={color} />
            <View style={{ flex: 1, marginLeft: 10 }}>
                <Text style={{ ...Fonts.text, marginBottom: 4 }}>{label}</Text>
                <Text style={{ ...Fonts.text, color: color }}>{value}</Text>
            </View>
        </View>
    </TouchableHighlight>
}

export default function ContactDetail({ route, navigation }) {
    const { actions: { getContact } } = React.useContext(AuthContext);
    const contact = getContact(route.params.contactId);

    function call() {
        Linking.openURL(`tel:${contact.phone}`)
    }

    function sendEmail() {
        Linking.openURL(`mailto:${contact.email}`)
    }

    return <SafeAreaView style={{ flex: 1 }}>
        <ScrollView style={{ flex: 1 }}>
            <View style={{ alignItems: 'center', paddingTop: 20, paddingBottom: 20 }}>
                <Image source={{ uri: contact.image_url }} style={{ height: 80, width: 80, borderRadius: 40 }} />
                <Text style={{ ...Fonts.title, marginTop: 10, marginBottom: 4 }}>{contact.name}</Text>
                <Text style={{ ...Fonts.subtitle }}>{contact.role}</Text>
            </View>
            <View >
                <ContactProperty icon="phone" label="Telefon" value={contact.phone} onTap={call} />
                <ContactProperty icon="email" label="Email" value={contact.email} onTap={sendEmail} />
                {(contact.office_hours !== undefined && contact.office_hours !== '') && <ContactProperty icon="clock" label="Bürozeiten" value={contact.office_hours} />
                }
            </View>
            <View style={{ padding: 12, paddingBottom: 30 }}>
                <PrimaryButton title="Anrufen" onPress={call} />
            </View>
        </ScrollView>
    </SafeAreaView>
}
