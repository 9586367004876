import React, { createContext, useContext, useReducer } from "react";
import { AuthContext } from "../../state/AuthContext";
import 'react-native-get-random-values';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { log, logError } from "../../services/logs";
import { Board, CreateBoard, CreatePin, CreateSpace, Pin, PinboardModel, PinModel } from "./PinboardTypes";
import { BoardStoreModel, migratePinboards, PinboardState } from "./PinboardStore";
import { Uploader } from "./Uploader";

const PinboardContext = createContext();

const reducer = (prevState: PinboardState, action): PinboardState => {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...prevState,
                boards: action.boards
            }
    }
    return prevState;
};

async function readCachedPinboards() {
    const result = await AsyncStorage.getItem('pinboards_v1');

    if (!result) return undefined;

    const boards = JSON.parse(result);

    const migratedBoards = migratePinboards(boards);

    console.info('migrated boards');

    return migratedBoards;
}

async function writeCachedPinboards(state) {
    if (!state) return;

    const string = JSON.stringify(state);
    AsyncStorage.setItem('pinboards_v1', string);
}

async function resetCachedPinboards() {
    console.info('resetCachedPinboards');

    const result = await AsyncStorage.getItem('pinboards_v1');
    if (result) {
        AsyncStorage.setItem('pinboards_backup', result);
    }

    AsyncStorage.removeItem('pinboards_v1');
}

function PinboardProvider({ boards, children }: { boards?: Board[], children: React.ReactNode }) {
    const { actions: { getPinboards, getAssetCreds, setKV, getKV } } = useContext(AuthContext);
    const [state, dispatch] = useReducer(reducer, { boards });

    async function UPDATE(params: PinboardState) {
        console.info('PinboardProvider update');

        writeCachedPinboards(params.boards);

        dispatch({ type: 'UPDATE', ...params });

        actions.upload(params);
    }

    const getStore = () => new BoardStoreModel(state, getAssetCreds());

    const uploader = new Uploader();

    const actions = {
        fetch: async () => {
            console.info('pinboard.fetch');

            try {
                let boards = await readCachedPinboards();
                if (boards) {
                    console.info('pinboard.fetch - found cached');
                    dispatch({ type: 'UPDATE', boards });
                    actions.upload({ boards });
                    return;
                }

                const res = await getKV('pinboards');
                if (res.value) {
                    console.info('pinboard.fetch - found cloud');
                    const { boards } = res.value;
                    dispatch({ type: 'UPDATE', boards });
                    return;
                }

                console.info('pinboard.fetch - init');
                boards = getPinboards() || [];
                dispatch({ type: 'UPDATE', boards });
            } catch (err) {
                logError(err);
            }
        },
        async writeState(state: PinboardState) {
            const success = await setKV('pinboards', state);

            if (success) {
                await resetCachedPinboards();
            }
        },
        async upload(state: PinboardState) {
            const { err, value } = await uploader.uploadPending(state, getAssetCreds());

            if (err) {
                return;
            }

            if (value) {
                UPDATE(value);
                this.upload(value);
                return;
            }

            await this.writeState(state);
        },
        getBoardData: (id: string) => {
            return getStore().getBoard(id);
        },
        resetPinboards: async () => {
            console.info('resetPinboards');

            await resetCachedPinboards();
            await actions.fetch();
        },
        getBoards() {
            return getStore().boards;
        },
        createBoard: (params: CreateBoard) => {
            const newState = getStore().createBoard(params);

            if (newState) {
                UPDATE(newState);
            }
        },
        renameBoard: (id: string, name: string) => {
            const newState = getStore().renameBoard(id, name);

            if (newState) {
                UPDATE(newState);
            }
        },
        deleteBoard: (id: string) => {
            const newState = getStore().deleteBoard(id);

            if (newState) {
                UPDATE(newState);
            }
        },
        getPinData: (boardId: string, itemId: string) => {
            return getStore().getPin(boardId, itemId);
        },
        renamePin: (boardId: string, itemId: string, title: string) => {
            console.info('renamePin', boardId, itemId, title);
            actions.setPinProperty(itemId, 'title', title);
        },
        setPinNotes: (itemId: string, notes: string) => {
            actions.setPinProperty(itemId, 'notes', notes);
        },
        setPinProperty(itemId: string, property: string, value: string | undefined) {
            const newState = getStore().setPinProperty(itemId, property, value);

            if (newState) {
                UPDATE(newState);
            }
        },
        createPin: async (boardId: string, params: CreatePin) => {
            const newState = await getStore().createPin(boardId, params);

            if (newState) {
                UPDATE(newState);
            }
        },
        deletePin(boardId: string, itemId: string) {
            const newState = getStore().deletePin(boardId, itemId);

            if (newState) {
                UPDATE(newState);
            }
        },
        createSpace: (params: CreateSpace) => {
            const newState = getStore().createSpace(params);

            if (newState) {
                UPDATE(newState);
            }
        }
    };

    return <PinboardContext.Provider value={{ state, actions }}>
        {children}
    </PinboardContext.Provider>
}

export { PinboardContext, PinboardProvider };